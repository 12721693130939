.faq-page-content {
  min-height: 70vh;
  .toggle {
    width: 100%;
    min-height: 120px;
    position: relative;
    margin-bottom: 40px;
    padding: 30px 35px;
    .head p {
      font-size: 16px;
      display: block;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.47;
      letter-spacing: 0.9px;
    }
    .content p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.73;
      letter-spacing: 0.7px;
    }
    .btn {
      position: absolute;
      bottom: -10px;
      left: calc(50% - 80px);
      width: 160px;
      height: 26px;
      line-height: 16px;
    }
  }
  .faq-col-right {
    display: none;
  }
  @media (min-width: 991px) {
    .faq-col-left {
      .toggle:nth-of-type(2n) {
        display: none;
      }
    }
    .faq-col-right {
      display: block;
    }
  }
}
