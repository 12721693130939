.a-propos,
.about-us,
.garantie-ino,
.ino-guarantee,
.ressources-ino,
.resources-ino,
.distributeur-ino,
.distributer-ino,
.nous-joindre,
.contact {
  .page-content {
    margin-top: 0px;
  }

  .customizable_content {
    position: relative;
    min-height: initial;
    margin-top: 0;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    h2 {
      margin-bottom: 1rem;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      font-size: clamp(12px, 2vw, 20px);
      line-height: clamp(16px, 2.5vw, 26px);
      margin-bottom: 3rem;
    }

    img {
      margin-top: 40px;
      max-width: 320px;
      max-width: clamp(400px, 50vw, 600px);
    }
  }

  .why_ino {

    .title_why {
      background-color: #e4e6eb;

      h2 {
        margin-bottom: 0;
      }

      .title_container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
      }
    }

    .content_why {
      overflow: hidden;

      .background_why {
        .color_background {
          background-color: #007279;

          .container_text {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: 70px;
            margin-top: 5rem;
            margin-bottom: 5rem;


            @media screen and (max-width: 1200px) {
              padding-right: 20px;
              align-items: center;
              justify-content: center;
            }

            @media screen and (max-width: 1200px) {
              padding: 0 40px;
            }

            h3 {
              color: #fff;
              margin-bottom: 15px;
              max-width: 450px;
            }

            .content_why-text {
              color: #fff;
              max-width: 450px;
              p {
                color: #fff;
                line-height: clamp(16px, 2.5vw, 26px);
                font-size: clamp(12px, 2vw, 20px);
              }
            }
          }
        }

        .color_background_reverse {
          background-color: #e4e6eb;

          .container_text_reverse {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 70px;
            margin-top: 5rem;
            margin-bottom: 5rem;

            @media screen and (max-width: 1200px) {
              padding-left: 20px;
              align-items: center;
              justify-content: center;
            }

            @media screen and (max-width: 1200px) {
              padding: 0 40px;
            }

            h3 {
              color: #000;
              margin-bottom: 15px;
              max-width: 450px;
            }

            p {
              color: #000;
              max-width: 450px;
              font-size: clamp(12px, 2vw, 20px);
              line-height: clamp(16px, 2.5vw, 26px);
            }
          }
        }

        .image_background {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          @include media-breakpoint-down(md) {
            height: 320px;
          }

          @include media-breakpoint-down(sm) {
            height: 320px;
          }

        }
      }

    }
  }

  .information-box {
    position: relative;
    min-height: 360px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #FEFEFE 0%, #E4E4E4 100%);
    border: 1px solid #E5E6EB;

    .content_history {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 40px;
      max-width: 800px;
      margin: auto;

      h2 {
        margin-bottom: 0;
        text-align: center;
        font-family: 'mark_proe';
        font-weight: 700;
        margin-bottom: 15px;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        text-align: center;
      }

      .button_history {
        background-color: #0C7C83;
        padding: 4px 24px;
        border-radius: 18px;
        color: #fff;
        text-decoration: none;


        .fa-angle-right {
          margin-left: 6px;
        }
      }
    }

  }

  .information-box-link {
    position: relative;
    min-height: 200px;
    width: 100%;
    height: 100%;

    &.distributor {
      min-height: 200px;

      .content_history {
        .button_history {
          background-color: #fff;
          color: #000;
          padding: 8px 24px;
          border-radius: 30px;
          transition: all .3s;

          &:hover {
            background-color: #0C7C83 !important;
            color: #fff !important;
            text-decoration: none;
            border-color: #0C7C83;
            transition: all .3s;
          }
        }
      }
    }

    .content_history {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 40px;
      max-width: 600px;
      margin: auto;
    }

  }

  .information-box-link-garantie {
    background-color: #e4e6ea;

    .fa-chevron-right {
      margin-left: 6px;
      font-size: 14px;
    }
  }

  .information-box-image {
    position: relative;
    min-height: 300px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #FEFEFE 0%, #E4E4E4 100%);
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .content_history {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 40px;
      max-width: 900px;
      margin: auto;

      .triangle_icon {
        max-width: 120px;
      }

      h2 {
        margin-bottom: 1rem;
        color: #fff;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        color: #fff;
      }
    }

  }

  .main-section-items {

    .title-main-section-items {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;

      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: center;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        margin-bottom: 3rem;
      }
    }

    .background-item {
      margin-bottom: 14px;
      padding-right: 7px;
      padding-left: 7px;

      .box {
        background-color: #047279;
        height: 100%;

        .item-header {
          justify-content: center;

          img {
            height: 80px;
            object-fit: cover;
            object-position: center;
            margin-right: initial;
          }
        }

        .content-description {
          font-size: 16px;
          text-align: center;
          color: #fff;

          p {
            font-size: clamp(12px, 2vw, 20px);
            line-height: clamp(16px, 2.5vw, 26px);
            color: #fff;

            a {
              color: #fff;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

    }

  }

  .temoignages {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .slider-temoignages {
      background-color: #fff;
      border-radius: 112px;

      .slick-track {
        display: flex;

        .slick-slide {
          display: flex;
          height: auto;
          align-items: center; //optional
          justify-content: center; //optional
        }

        .slick-slide * {
          height: inherit;
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }

      p {
        color: #000;
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
      }

      .companie {
        font-family: 'mark_proe';
        font-weight: 700;
      }

      .slick-dots {
        position: relative;
      }

    }
  }

  .resources_container {
    .mobile-none {
      @media (max-width: 768px) {
        display: none;
      }
    }

    .mobile-show {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    #main #faq .card {
      border: 0;
      border-radius: 0;
      background-color: #27929a;

      .card-header {
        border: 0;
        padding: 0;
        border-top: 1px solid #f6f6f8;

        .btn {
          border: 0;
        }
      }

      .card-body {
        color: #fff;
        padding-top: 0;
      }
    }

    #main #faq .card .card-header .btn-header-link {
      color: #fff;
      display: block;
      text-align: left;
      background: #27929a;
      padding: 20px 10px;
      border-radius: 0;
      font-size: 16px;
      text-transform: uppercase;
    }

    #main #faq .card .card-header .btn-header-link:after {
      content: "\f107";
      font: normal normal normal 14px/1 FontAwesome;
      font-weight: 900;
      float: right;
      font-size: 24px;
    }

    #main #faq .card .card-header .btn-header-link.collapsed {
      background: #27929a;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      border-radius: 0;
    }

    #main #faq .card .card-header .btn-header-link.collapsed:after {
      content: "\f106";
      font-size: 24px;
    }

    #main #faq .card .collapsing {
      background: #27929a;
      line-height: 30px;
    }

    #main #faq .card .collapse {
      border: 0;
    }

    #main #faq .card .collapse.show {
      background: #27929a;
      line-height: 30px;
      color: #222;

      .card-body {
        color: #fff;
        padding-top: 0;
      }
    }

    .resource_filter,
    .resource_filter_post {
      align-items: center;
      outline: 1px solid #fff;
      border-radius: 25px;
      padding: 20px 30px;
      height: 80px;

      p {
        color: #fff;
        font-size: clamp(16px, 2vw, 20px);
        line-height: clamp(20px, 2.5vw, 26px);
      }

      &.active_filter {
        font-family: "mark_proe";
        font-weight: 700;
        outline: 4px solid #fff;

        p {
          font-family: "mark_proe";
          font-weight: 700;
          color: #fff;
        }

        .fa-chevron-right {
          font-weight: 700;
        }
      }

      &:hover {
        font-family: "mark_proe";
        font-weight: 700;
        outline: 4px solid #fff;

        p {
          font-family: "mark_proe";
          font-weight: 700;
        }

        .fa-chevron-right {
          font-weight: 700;
        }
      }

      a {

        &.link_sector {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        color: #fff;
        text-decoration: none;
      }
    }

    .document {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      img {
        width: 25px;
      }
    }
  }

}
