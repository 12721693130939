.product-box {
  margin-bottom: 0px;
  border-radius: 2px;
  position: relative;
  min-height: 180px;
  height: 100%;

  .product__overlay {
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    display: block;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    border: 5px solid #008C99;

    .btn_news,
    .btn_product {
      width: initial;
      height: auto;
      position: absolute;
      text-align: center;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.6px;
      line-height: 20px;
      text-align: center;
      padding: 8px 18px;
      background-color: #008C99;
      border-radius: 18px;
      color: #fff;
      align-self: center;
      text-decoration: none;
      text-transform: uppercase;
      border-color: none;
    }

    &:hover {
      opacity: 1;
      border: 5px solid #008C99;

      .btn_news,
      .btn_product {
        width: initial;
        height: auto;
        position: absolute;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.6px;
        line-height: 20px;
        text-align: center;
        padding: 8px 14px;
        background-color: #008C99;
        border-radius: 18px;
        color: #fff;
        align-self: center;
        text-decoration: none;
        text-transform: uppercase;
        border-color: none;
      }
    }

  }

  .product__overlay:hover~.product-box-inner {
    a {
      opacity: 0.5;
    }
  }

  .product-box-inner {
    padding: 10px 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;

    .image {
      height: 291px;
      width: 100%;
      padding: 40px 0;

      &>div {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .img_responsive_product {
        height: 100%;
        width: 100%;
        object-fit: none;
        object-position: center;
      }


    }

    .description {
      padding-top: 0px;
      margin-top: 10px;
      flex-grow: 1;
      width: 100%;
      height: auto;
      text-align: center;

      .product-brand {
        text-transform: uppercase;
        font-size: 10px;
      }

      a:hover {
        text-decoration: none;
      }

      .product-code-name {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        height: initial;
        width: initial;
        font-family: 'mark_proe';
        font-weight: 700;
      }

      .product-description {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 8px;
        height: auto;
      }

      .logos-and-prices {
        display: flex;
        justify-content: space-between;

        .logos {
          margin-right: 15px;

          >* {
            width: 15px;
            // height: 15px;
            margin-right: 4px;
          }
        }

        span {
          font-size: 28px;
          line-height: 33px;
        }

        sup {
          font-size: 100%;
        }
      }

      .price {
        font-weight: 600;
        letter-spacing: -1px;
      }
    }
  }

  .product-box-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .btn {
      color: white;
      font-size: 12px;
      width: 100%;
      border-radius: 0;
    }
  }
}
