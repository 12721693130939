.page-header {
  display: flex;
  margin-top: 50px;
  margin-bottom: 90px;
  color: theme-color(white);
  position: relative;

  .container {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .header-container {
    width: 100%;
  }

  .header-image-container {
    width: 100%;
    height: 400px;
    margin-bottom: -100px;
  }

  .cover-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      content: "";
    }
  }

  h1::after {
    background-color: #fff;
    opacity: 0.3;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    margin-bottom: 30px;

    .row {
      flex-direction: column-reverse;
      .header-image-container {
        width: calc(100% + 30px);
        height: 100%;
        margin-bottom: 0;
        margin-left: -15px;
        margin-right: -15px;
        img {
          max-width: 100%;
        }
      }
    }

    .container {
      padding-top: 0;
    }

    h1 {
      margin-top: 30px;
    }
    .slick-arrow {
      &.slick-prev {
        left: 10px;
      }
      &.slick-next {
        right: 10px;
      }
    }
  }
}
