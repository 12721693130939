div.shop-root,
div.product-root {
  min-height: 500px;

  .shop {
    /* .container {
    margin-bottom: 90px;
  } */
    min-height: 1000px;

    &.empty-shop {
      min-height: 400px;
    }

    .backdrop {
      position: absolute;
      background: black;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
    }

    .sidebar-container {
      // border-right: 1px solid #2b9ca2;
      padding-right: 50px;
      position: relative;
      margin-bottom: 90px;
      user-select: none;

      &::after {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        width: 1px;
        height: 100%;
        content: "";
      }
    }

    .main-item-container {
      margin-bottom: 90px;
    }

    .loading {
      padding-top: 130px;
    }

    /*   :focus {
    outline: 0 !important;
  } */

    .btn {
      outline: none;
    }

    .filter-category-title {
      margin-top: 10px;
      letter-spacing: 0;
      font-size: 15px;
      padding: 5px 0;
    }

    .list-side-bar {
      padding: 8px 0;

      i.fa {
        transition: transform 0.3s ease-in-out;
        margin: 6px;
      }

      .collapsed i.fa {
        transform: rotate(-90deg);
      }
    }

    .Sidebar {
      a {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        display: inline-block;
        margin-bottom: 3px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          text-decoration: none;
        }

        &.active {
          font-weight: 700;
        }

        span.counter {
          font-size: 11px;
          margin-left: 8px;
          font-weight: 600;
          letter-spacing: 0;
        }
      }

      .back-to-all-categories {
        padding-bottom: 10px;
      }

      .Sidebar-category {
        padding-bottom: 5px;
        margin-left: 20px;

        .Sidebar-category-name {
          padding-bottom: 5px;
          // margin-left: 10px;
        }

        .Sidebar-subCategory {
          padding-bottom: 5px;
          margin-left: 20px;

          .Sidebar-subCategory-name {
            //
          }

          .Sidebar-subSubCategories {
            padding-bottom: 5px;
            margin-left: 20px;

            a {
              font-size: 13px;
            }

            //
          }
        }
      }

      i.fa {
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0;
        }
      }
    }

    .filter-sidebar-container {
      margin-top: 30px;

      .filter-sidebar-card {
        padding: 25px;
        margin-top: 20px;

        i.fa {
          transition: transform 0.3s ease-in-out;
        }

        .collapsed i.fa {
          transform: rotate(-90deg);
        }

        .filter-title-box {
          display: flex;
          flex-flow: row;
          justify-content: space-between;

          .title {
            font-size: 13px;
            font-weight: 600;
          }
        }

        span {
          color: theme-color(black);
          margin-left: 20px;
          font-size: 12px;
        }
      }
    }

    .main-item-header {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      margin-bottom: 30px;

      span.results-count {
        font-size: 13px;
      }

      span.results-name {
        font-size: 13px;
        padding-left: 4px;
      }
    }

    .items-filter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;

      @include media-breakpoint-down(sm) {
        justify-content: space-between;

        .item-filter {
          width: 100%;
        }
      }
    }

    .item-filter {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      min-width: 120px;
      //height: 60px;
      border-top: none;
      border-left: none;
      border-right: none;

      &+.item-filter {
        margin-left: 35px;
      }

      select {
        border: 0;
        font-size: 13px;
        padding-left: 0;
        margin-bottom: 0;

        &:focus {
          box-shadow: none;
        }
      }

      span {
        font-size: 11px;
        font-weight: 600;
      }
    }

    .item-card {
      position: relative;
      margin-bottom: 30px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      &.disabled {
        opacity: 0.3;
      }

      .item-card-bottom {
        margin-top: 5px;

        button {
          color: white;
        }
      }

      .item-card-status {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -12px;
        right: 0;
        left: 0;
        z-index: 1;

        span {
          display: block;
          font-size: 10px;
          background-image: linear-gradient(260deg, #ff5166, #f86);
          padding: 4px 8px;
          color: theme-color(white);
          border-radius: 20px;
        }
      }

      .item-card-image {
        position: relative;
        width: 100%;
        //   padding-top: 12px;
        //   padding-right: 22px;
        padding-bottom: 100%;
        user-select: none;
        //   padding-left: 22px;

        a,
        .link {
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 10px;
          display: block;
        }

        .item-image {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        // &:hover {
        //   border-color: theme-color(green);
        // }
      }

      .item-title {
        margin-top: 7px;
        margin-bottom: 16px;
        font-size: 9px;

        div img {
          float: right;
        }

        span,
        a,
        .product-name {
          font-weight: 600;
          font-size: 15px;

          &:hover {
            text-decoration: none;
          }
        }

        i {
          float: right;
          font-size: 15px;
        }
      }

      &.item-card-category {
        .item-card-image {
          transition: border-color 0.15s ease-in-out;
          border: 1px solid #fff;
        }

        // &:hover {
        //   .item-card-image {
        //     border-color: theme-color(green);
        //   }
        //   .item-title {
        //     background-color: theme-color(green);
        //   }
        // }
        .item-title {
          background-color: theme-color(black);
          margin-top: 0;
          margin-bottom: 0;
          height: 100%;
          transition: background-color 0.15s ease-in-out;
          display: flex;
          align-items: center;

          a.product-name {
            padding: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }

    .item-description {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      overflow: hidden;
      white-space: pre-line;
      text-overflow: ellipsis;
    }

    .logos-and-prices {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .logos {
        margin-right: 15px;

        >* {
          width: 15px;
          // height: 15px;
          margin-right: 4px;
        }
      }

      span {
        font-size: 28px;
        line-height: 33px;
      }

      sup {
        font-size: 100%;
      }
    }

    .item-price {
      font-weight: 600;
      margin-top: 17px;
      margin-bottom: 20px;
    }

    .shop-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 45px auto;

      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

      .pagination {
        margin-bottom: 0;

        &-list {
          flex-wrap: wrap;
        }

        li {
          margin-right: 20px;
          font-size: 14px;
          font-weight: 600;
          width: 20px;
          height: 20px;
          text-align: center;
          position: relative;
          border-radius: 4px;
          display: block;
          line-height: 22px;
          letter-spacing: 0;

          &::after {
            position: absolute;
            top: 0;
            margin: auto;
            bottom: 0;
            right: -12px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: theme-color(black);
            content: "";
          }

          &.active {
            a {
              color: theme-color(white);
            }
          }
        }

        .last {
          margin-right: 0;
        }

        .prev {
          margin-right: 10px;
        }

        .next {
          margin-left: 10px;
          margin-right: 0;
        }

        .prev,
        .next,
        .last {
          &::after {
            display: none;
          }
        }

        a {
          display: block;
          height: 20px;
          line-height: 20px;
          transition: color 0.3s ease-in-out;

          &:hover {
            text-decoration: none;
          }
        }

        i.fa {
          font-size: 15px;
          line-height: 20px;
          height: 20px;
        }
      }
    }

    .calculator-section {
      padding: 25px;
      margin-top: 20px;
      border: none;
      border-radius: 0;
      color: black;

      .main-title {
        font-size: 23px;
        font-weight: 700;
        font-style: italic;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      .sub-title {
        font-size: 11px;
        font-style: italic;
        margin-bottom: 10px;
      }
    }
  }
}

.shop-root.single-product {
  &+.pre-footer-actions {
    display: none;
  }
}

body.shop,
div.product-root,
body.single-product {
  h1 {
    font-size: 2rem;

    &+h2 {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: none;
      margin-top: 3rem !important;
      margin-bottom: 1rem !important;

      &::after {
        display: none;
      }
    }
  }

  .home-head {
    // overflow: hidden;
    position: relative;
    min-height: initial;
    margin-top: 0;
    @include media-breakpoint-down(sm) {
      background-image: var(--backgroundMobile) !important;
    }

    // @include media-breakpoint-up(md) {
    //   margin-top: 45px;
    // }
    /*
    Removes white gap between slides - chagnge to base color of slide images
    */
    .carousel {
      background: #ffffff;

      ol.carousel-indicators {
        bottom: -55px;
      }
    }

    /*
    Forces image to be 100% width and not max width of 100%
    */
    .carousel-item .img-fluid {
      width: 100%;
    }

    .carousel-caption {
      position: relative;
      right: initial;
      bottom: initial;
      left: initial;
      z-index: 10;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;

      .content-caption {
        max-width: 50%;

        @include media-breakpoint-down(md) {
          max-width: 70%;
        }

        @include media-breakpoint-down(sm) {
          max-width: 85%;
        }
      }

      h2 {
        color: #000000;
        font-family: "mark_proe";
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      img {
        max-width: 230px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          max-width: 180px;
          margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
          max-width: 160px;
          margin-bottom: 20px;
        }
      }

      p {
        color: #000000;
        font-family: "mark_pro";
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        margin: 0;

        @include media-breakpoint-down(sm) {
          font-size: clamp(16px, 2vw, 30px);
          line-height: clamp(20px, 2.5vw, 36px);
        }
      }

      .btn {
        background-color: #0C7C83;
        padding: 4px 20px;
        border-radius: 18px;
        color: #fff;
        -ms-flex-item-align: end;
        align-self: flex-end;
        text-decoration: none;
        border-color: #0C7C83;
      }
    }

    /*
    anchors are inline so you need ot make them block to go full width
    */
    .carousel-item {
      display: block;
      width: 100%;

      picture {
        img {

          @include media-breakpoint-down(md) {
            min-height: 280px;
            object-fit: cover;
            object-position: center;
          }

          @include media-breakpoint-down(sm) {
            min-height: 320px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }


    .cover-image {
      @include media-breakpoint-down(sm) {
        background-position: left;
      }
    }
  }

  .breadcrumbs-container {
    padding-top: 0;
    background: #E5E6EB;
    margin-bottom: 0;
    min-height: 40px;

    .path {
      @include media-breakpoint-down(sm) {
        flex-flow: row wrap;
      }

      div {
        display: flex;
        height: 30px;
        align-items: center;
      }

      a {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 600;

        &.active {
          color: black;
          font-weight: 600;
        }
      }

      span {
        margin-right: 10px;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
      }

      &>div:first-of-type {
        a {
          margin-left: 0;
        }
      }
    }
  }
}

.suppliers-page {

  //   @include media-breakpoint-down(sm) {
  //     text-align: center;
  //   }
  .suppliers-page-title {
    margin-top: 50px;
    margin-bottom: 80px;
  }

  a {
    text-decoration: none;
  }

  &>.row {
    margin-bottom: 45px;
  }

  .supplier-box {
    margin-bottom: 45px;

    .logo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid lightgray;
      text-align: center;
      height: 110px;
    }

    .cover-image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 90%;
      width: 90%;
    }
  }

  .left-sup {
    display: flex;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
    height: 110px;
    align-items: center;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  //   .right-sup {
  //     display: flex;
  //     @include media-breakpoint-down(sm) {
  //       justify-content: center;
  //     }
  //   }

  .supplier-name {
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
}

.btn-filter-bar {
  width: 100%;
  font-weight: 600;
  display: flex;
  flex-flow: row;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }
}

.certifications-page {

  //   @include media-breakpoint-down(sm) {
  //     text-align: center;
  //   }
  .certifications-page-title {
    margin-top: 50px;
    margin-bottom: 80px;
  }

  &>.row {
    margin-bottom: 45px;
  }

  a {
    text-decoration: none;
  }

  .certifications-box {
    margin-bottom: 55px;

    .logo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
    }

    .cover-image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 80%;
      width: auto;
    }

    .certification-name {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      font-weight: 600;
      font-size: 15px;
      text-align: center;
    }
  }
}

.empty-cart,
.submitted {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 200px;

  span {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 32px;
  }

  .icon {
    margin-bottom: 32px;
  }

  &::before {
    top: -24%;
    z-index: -1;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    width: 420px;
    height: 420px;
    border-radius: 50%;
    background-color: #f6f6f6;
  }
}

.main-item-container .submitted {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 768px) {

  .empty-cart,
  .submitted {
    span {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .icon {
      margin-bottom: 20px;

      img {
        height: 80px;
      }
    }

    &::before {
      top: -12%;
      z-index: -1;
      position: absolute;
      content: "";
      display: flex;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      background-color: #f6f6f6;
    }
  }
}
