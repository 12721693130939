.page-download {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 70vh;

  h1 {
    margin-bottom: 70px;
  }

  .download-box-container {
    padding: 20px 30px;
    flex-flow: column nowrap;
    & > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;

      &.btn-container {
        justify-content: center;
        margin-bottom: -35px;
        margin-top: 20px;
      }
      .title {
        border-bottom: 1px solid theme-color(black);
        margin-bottom: 9px;
      }

      .download-icon {
        background-color: white;
        margin-right: 20px;
      }
    }
  }
}
