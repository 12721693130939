.calculator-modal {
  .modal-dialog {
    max-width: 940px;
  }
  .modal-content {
    padding: 0;
    min-height: 510px;
    .modal-header {
      padding: 25px 32px;
      width: 100%;
      background: black;
      color: #fff;
      font-weight: 600;

      .header-title {
        cursor: pointer;
        transition: all ease-in-out 200ms;
      }
      .close {
        margin: 0;
        color: #fff;
        padding: 0;
      }
    }
    .modal-title {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .modal-body {
      padding: 0;
    }
    .first-line {
      background-color: #fcfcfc;
      min-height: 155px;
    }
    .second-line {
      min-height: 120px;
    }

    .second-line,
    .first-line {
      display: flex;
      flex-flow: row;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      .logo {
        margin-right: 20px;
      }
      .content-container {
        display: flex;
        flex-flow: column;
      }

      .input-boxes {
        display: flex;
        flex-flow: row;
        margin-right: 15px;
        .input-box {
          display: flex;
          flex-flow: column;
          margin-right: 15px;
          font-size: 13px;
          input {
            width: 120px;
            min-height: 30px;
            max-height: 60px;
          }
          span {
            margin-right: 3px;
          }
        }
      }
      .title {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 10px;
      }

      .result-box {
        display: flex;
        align-items: flex-end;

        button {
          width: 150px;
          margin-right: 25px;
          position: relative;

          &::after {
            position: absolute;
            content: "";
            width: 10px;
            height: 2px;
            background: black;
            left: 115%;
            top: 13px;
          }
          &::before {
            position: absolute;
            content: "";
            width: 10px;
            height: 2px;
            background: black;
            left: 115%;
            top: 17px;
          }
        }

        input.result {
          border: none;
          color: #007c85;
          font-weight: 600;
          font-size: 15px;
          background: transparent;
          width: 100px;
          min-height: 32px;
          margin-right: 10px;
          margin-left: 10px;
          text-align: center;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
    }

    .first {
      padding-left: 20px;
    }
  }
}
