.breadcrumb {
  background-color: #E5E6EB;
  border-radius: 0;

  @media (max-width: 375px) {
    padding: 0.75rem 0.7rem;
  }
}

.breadcrumb-item+.breadcrumb-item {

  @media (max-width: 575px) {
    padding-left: 0.3rem;
  }
}

.breadcrumb_container {

  margin-top: -15px;

  .breadcrumb_new {
    display: flex;

    @media (max-width: 575px) {
      padding: 0 !important;
    }

    .breadcrumb-item {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 17px;
    }

    .content_item_li {
      &::before {
        content: "<";
        color: #007c85;
        padding-right: 0.6rem;

        @media (max-width: 575px) {
          padding-right: 0.3rem;
        }
      }
    }

    .home_sanit {
      color: #007c85;
      // background-image: url("../images/home.svg");
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      width: 16px;
      height: 16px;
      background-size: 100%;

      &:hover {
        // background-image: url("../images/home_gris.svg");
      }
    }

    .item_id_page {
      color: #007c85;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 17px;
      text-align: justify;

      &:hover {
        color: #0f8283;
      }
    }

    .active {
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 17px;
      text-align: justify;
      color: #000;
    }
  }

}

.content-border-space {
  @include media-breakpoint-down(sm) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.content_post {
  width: 100%;
  height: auto;
  margin: 30px 0;

  .content_post_inside {
    width: 100%;
    height: 100%;

    .updated {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .entry-title {
      margin-top: 20px;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 41px;
      margin-bottom: 60px;
      text-transform: initial;

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 5px;
        width: 50px;
        left: 0;
        right: auto;
        margin: auto;
        bottom: -20px;
        background-color: #0f8283;
      }
    }

    .text_last {
      margin-bottom: 30px;

      h1 {
        font-size: 2.25em;
        line-height: 1.2;
      }

      h2 {
        font-size: 2rem;
        line-height: 1.2;
        text-transform: initial;
        margin-bottom: 25px;
      }

      p {
        letter-spacing: 0.3px;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    .file_post {
      margin: 0;
    }
  }

  .border_rad {
    border-radius: 10px;
    background-color: #efefef;
  }
}

.more_post {
  width: 100%;
  height: auto;
  margin: 30px 0;
  padding: 0 22px;

  .content_more_posts {
    background-color: #efefef;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 22px;

    .recent_post {
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .block {
      padding: 0;

      .news {
        .card {
          background-color: transparent;
          border: 1px solid #d4d8dd;
          box-sizing: border-box;
          border-radius: 0;

          .img_news {
            width: 100%;
          }

          .card-body {
            background-color: #fff;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 30px;
            padding-bottom: 42px;

            .updated {
              font-size: 12px;
              letter-spacing: 0;
              line-height: 17px;
            }

            .entry-title {
              margin-top: 12px;
              font-size: 21px;
              letter-spacing: 0;
              line-height: 28px;

              &::after {
                background-color: #0f8283;
              }
            }
          }
        }
      }
    }
  }
}

.home-newsletter {
  min-height: 230px;
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: 90px;
    padding-bottom: 90px;
    text-align: left;
  }

  .description {
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: initial;
      text-align: left;
    }
  }

  .input-container {
    position: relative;

    input {
      width: 100%;
      height: 50px;
      border: none;
      padding: 10px 60px 10px 20px;
      border-radius: 26px;
      font-size: 20px;
    }

    .arrow {
      position: absolute;
      top: 0;
      right: 35px;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      background-color: theme-color(black);
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 13px;
        height: 2px;
        border-radius: 1px;
        background-color: theme-color(black);
      }

      &::before {
        transform: rotate(45deg);
        transform-origin: center right;
      }

      &::after {
        transform: rotate(-45deg);
        transform-origin: center right;
      }

      input {
        position: absolute;
        background-color: transparent;
        width: 40px;
        height: 50px;
        left: -25px;
        top: -24px;
        cursor: pointer;
      }
    }

    .CIMessage {
      position: absolute;
      left: 0;
      top: 110%;
      font-size: 12px;
      user-select: none;
      padding-left: 5px;
    }
  }
}
