.mobile-menu {
  height: calc(100vh - 75px);
  top: 0;
  width: 100%;
  max-width: 500px;
  background: #fff;
  color: black;
  position: absolute;
  left: -100%;
  overflow: hidden;
  z-index: 1000;
  transition: all ease-in-out 300ms;

  &.active {
    left: 0;
    box-shadow: 3px 0 11px 1px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(lg) {
      left: -100%;
    }
  }

  .menu-container {
    position: relative;
    height: calc(100vh - 75px - 38px);
    overflow: auto;
  }

  .step1 {
    width: 100%;
  }

  .language {
    font-size: 12px;
    font-weight: 600;
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: theme-color(black);

    a {
      color: #fff;
      font-weight: 700;
    }

    i.fa {
      color: #fff;
      float: right;
      font-size: 15px;
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  a.category-link {
    color: theme-color(black);
    display: flex;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .menu-item {
    font-size: 12px;
    font-weight: 600;
    padding: 0 15px;
    height: 40px;
    background: theme-color(black);
    color: #fff;
    border-top: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .show i.fa {
    transform: rotate(90deg);
  }

  .back {
    display: flex;

    i.fa {
      padding-right: 15px;
      float: none;
    }
  }

  .category-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0 15px;
    height: 36px;
    cursor: pointer;

    i.fa {
      color: black;
    }

    span.title {
      display: block;
    }
  }

  .menu-column {
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 106%;
    top: 0;
    transition: all ease-in-out 300ms;
    box-shadow: -3px 0 11px 1px rgba(0, 0, 0, 0.1);

    &.active {
      left: 0;
    }

    .links-container {
      height: calc(100vh - 75px - 40px - 40px - 70px);
      overflow-y: scroll;
    }
  }
}

.menu-dropdown {
  @include media-breakpoint-down(md) {
    height: 40px;
    align-items: center;
  }

  .products-search {
    height: 40px;
    position: relative;
    z-index: 2;
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    i.fa {
      padding-left: 10px;
    }
  }
}

.logos-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 15px 15px 0;
  max-height: calc(100vh - 75px - 160px - 70px);
  overflow-y: scroll;

  .logo-container {
    min-height: 100px;
    width: 20%;
    min-width: 100px;
    margin-bottom: 15px;
    padding: 0 5px;
    font-size: 11px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: theme-color(black);

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      min-height: 80px;
    }

    &>div.logo {
      width: 80%;
      height: 100px;
      display: block;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 10px;

      @include media-breakpoint-down(md) {
        height: 50px;
      }
    }
  }
}

.sectors {
  .sectors-links {
    min-height: 36px;
    width: 100%;
    text-align: inherit;
  }
}
