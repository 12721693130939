.carousel--banner {
  .carousel {
    background: #ffffff;

    ol.carousel-indicators {
      bottom: -55px;
    }
  }

  /*
  Forces image to be 100% width and not max width of 100%
  */
  .carousel-item .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-item div {

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        @include media-breakpoint-down(sm) {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 350px;
    z-index: 10;
    color: #000000;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 60px 30px;
    @include media-breakpoint-down(sm) {
      padding: 60px 40px;
      min-height: 320px;
    }

    .content-caption {
      max-width: 50%;

      h2 {
        margin-bottom: 1rem;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);

        @include media-breakpoint-down(sm) {
          font-size: clamp(16px, 2vw, 30px);
          line-height: clamp(20px, 2.5vw, 36px);
        }
      }

      @include media-breakpoint-down(md) {
        max-width: 70%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 85%;
      }
    }

    .btn {
      background-color: #0C7C83;
      padding: 4px 20px;
      border-radius: 18px;
      color: #fff;
      -ms-flex-item-align: end;
      align-self: flex-end;
      text-decoration: none;
      border-color: #0C7C83;
    }
  }

  /*
  anchors are inline so you need ot make them block to go full width
  */
  .carousel-item a {
    display: block;
    width: 100%;

    picture {
      img {
        @include media-breakpoint-down(sm) {
          min-height: 225px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }


  .cover-image {
    @include media-breakpoint-down(sm) {
      background-position: left;
    }
  }
}
