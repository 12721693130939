footer {
  .footer-menu {
    background-color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .sani_footer {

      // margin-right: 15px;
      // margin-left: 15px;
      .links_footer {
        display: flex;
        justify-content: space-between;
        margin-top: 34px;

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          padding: 0 40px;
          justify-content: space-evenly;
        }

        .link {
          text-decoration: none;
          flex: 0 1 auto;

          &:hover {
            color: #fff;
            text-decoration: underline;
         }

          @include media-breakpoint-down(sm) {
            margin-left: 6px;
            margin-right: 6px;
          }

          span {
            color: #fff;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }

    .container {
      margin-left: 0;
      margin-right: 0;
    }

    .address {
      @include media-breakpoint-down(sm) {
        order: 3;
      }
    }

    .links_categories,
    .categories,
    .address {
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: 0 40px;
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: justify;
        color: #fff;
        padding-top: 40px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .line_footer_menu {
        width: 61px;
        border: 3px solid #2B9CA2;
        float: left;
        margin-top: 1.4rem;
        margin-bottom: 1rem;
      }
    }

    .social_media {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
      padding-bottom: 40px;

      .icon_social {

        border-radius: 100%;
        margin-left: 4px;
        margin-right: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-of-type {
          margin-right: 0;
        }

        img {

          height: 35px;
          width: 35px;
          text-align: center;
          object-fit: cover;
          object-position: center;

        }
      }

      .a2a_kit {
        position: absolute;
        top: initial !important;
        bottom: 30px;
        right: 0;
        display: flex;
        display: none;
      }

      @media (max-width: 992px) {
        padding-right: 40px;
      }
    }

    .address_sani_depot {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 0;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      flex-direction: column;

      .title_address_sani_depot {
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 26px;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }

      .address_address_sani_depot {
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 26px;
        margin: 0;
        padding: 0;
      }

      .postal_city {
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 26px;
        margin: 0;
        padding: 0;
      }
    }

    nav.nav-primary {
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 0;

      @include media-breakpoint-up(md) {
        // padding-bottom: 90px;
        // padding-top: 90px;
        padding-left: 0;
        padding-right: 0;
      }

      @include media-breakpoint-down(sm) {
        // padding-bottom: 90px;
        // padding-top: 90px;
        padding-left: 0;
        padding-right: 0;
      }

      ul.nav {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: space-between;

        // @include media-breakpoint-up(md) {
        //   flex-direction: row;
        // }

        a {
          display: block;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 5px;
          margin: 0;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 26px;
          // border-top: 1px solid transparent;
          // border-bottom: 1px solid theme-color(grey-3);
          // position: relative;
          position: static;
          //color: #2B9CA2;
          font-size: 16px;
          color: #fff;
          text-transform: capitalize;

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          // &::after {
          //   position: absolute;
          //   right: 0;
          //   bottom: 0;
          //   left: 0;
          //   height: 1px;
          //   width: 100%;
          //   background-color: transparent;
          //   display: block;
          //   content: "";
          //   transition: all 0.3s ease-in-out;
          // }

          &:hover {
            position: relative;
            color: #fff;

            &::after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .legal {
    display: flex;
    align-items: center;
    justify-content: center;

    .legal-text {
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

  }

  .legal-text {

    .social-icons {
      a {
        margin-right: 15px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 13px;
    }
  }

  .rights-reserved {
    color: #808080;
    text-align: left;
    font-size: 13px;

    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 13px;
      margin-bottom: 30px;
    }
  }

}
