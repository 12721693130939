.lignes-ino,
.ino-lines {
  .home-head {
    // overflow: hidden;
    position: relative;
    min-height: initial;
    margin-top: 0;

    // @include media-breakpoint-up(md) {
    //   margin-top: 45px;
    // }
    /*
    Removes white gap between slides - chagnge to base color of slide images
    */
    .carousel {
      background: #ffffff;

      ol.carousel-indicators {
        bottom: -55px;
      }
    }

    /*
    Forces image to be 100% width and not max width of 100%
    */
    .carousel-item .img-fluid {
      width: 100%;
    }

    .carousel-item div {

      picture {
        img {
          @include media-breakpoint-down(sm) {
            min-height: 320px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .carousel-caption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;

      .content-caption {
        max-width: 56%;

        @include media-breakpoint-down(md) {
          max-width: 70%;
        }

        @include media-breakpoint-down(sm) {
          max-width: 85%;
        }

        h2 {
          margin-bottom: 1rem;
        }

        p {
          font-size: clamp(12px, 2vw, 20px);
          line-height: clamp(16px, 2.5vw, 26px);

          @include media-breakpoint-down(sm) {
            font-size: clamp(16px, 2vw, 30px);
            line-height: clamp(20px, 2.5vw, 36px);
          }
        }
      }

      .btn {
        background-color: #0C7C83;
        padding: 4px 20px;
        border-radius: 18px;
        color: #fff;
        -ms-flex-item-align: end;
        align-self: flex-end;
        text-decoration: none;
        border-color: #0C7C83;
      }
    }

    /*
    anchors are inline so you need ot make them block to go full width
    */
    .carousel-item a {
      display: block;
      width: 100%;

      picture {
        img {
          @include media-breakpoint-down(sm) {
            min-height: 225px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }


    .cover-image {
      @include media-breakpoint-down(sm) {
        background-position: left;
      }
    }
  }

  .family_ino {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #EFEFEF;

    .ino_container {
      position: relative;
      z-index: 2;

      .families {
        padding-top: 70px;
        padding-bottom: 134px;

        .family {
          margin-bottom: 86px;

          .logo {
            padding: 28px 68px;
          }

          .text-body {
            padding: 1.25rem 3rem;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 18px;
              letter-spacing: 0;
              line-height: 23px;
            }
          }

          &:hover {
            text-decoration: none;
            color: #000000;

            .product {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .img_front {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      max-width: 626px;
      object-fit: cover;
      object-position: center;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .img_back {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      max-width: 985px;
      object-fit: cover;
      object-position: center;
    }
  }

}
