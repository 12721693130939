* {
  font-family: "mark_proe";
}

div.header {
  position: relative;
  //   width: 100%;
  //   z-index: 10;
}

#breadcrumbs {
  list-style: none;
  margin: 0;
  overflow: hidden;
}

.breadcrumbs_wordpress {
  background-color: #E5E6EB;
  padding-bottom: 15px;
}

#breadcrumbs li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;

  strong {
    font-size: 12px;
    font-weight: 600;
  }
}

header.banner {
  position: relative;
  z-index: 3;

  .row-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 56px;

    @media (max-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .brand {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    transition: height 0.3s ease-in-out;

    img {
      //   width: 100%;
      //   max-width: 300px;
      // max-width: 300px;
      // max-height: 55px;
      max-width: 280px;
      max-height: 45px;
    }
  }

  .branches-dropdown-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 auto;

    .menu-links {
      justify-content: flex-end;
      margin-right: 10px;

      a.link {
        border-right: 1px solid #000;
        padding: 0 10px;

        &:last-of-type {
          border: none;
        }

        span {
          color: #000;
          font-family: "mark_pro";
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 14px;
          transition: all 0.3s ease;
        }

        &:hover {
          span {
            color: #0f8283;
            transition: all 0.3s ease;
          }
        }
      }
    }

    a:hover {
      color: black;
      text-decoration: none;
    }

    .cart {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 5px;
      font-size: 12px;
      font-weight: 600;
      background: #fff;
      height: 50px;
      min-width: 85px;
      margin-left: 10px;
      margin-top: 0;
      z-index: -1;
      padding-top: 8px;

      span {
        margin-top: 2px;
      }

      img {
        margin-top: 2px;
        height: 20px;
        width: 20px;
      }

      @include media-breakpoint-down(sm) {
        float: right;
      }

      .up-line {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }

    @media (max-width: 992px) {
      .cart-link {
        position: absolute;
        right: 0;
        top: 27px;
        background: transparent;
        display: flex;
        align-items: center;

        .cart {
          background: transparent;

          .up-line {
            height: 100%;
          }
        }
      }
    }

    .find_distributeur {
      position: absolute;
      top: 60px;
      right: 100px;
      font-weight: 400;
      font-family: "mark_pro";

      .fa {
        color: #0f8283;
        margin-right: 10px;
      }

      &:hover {
        color: #0f8283 !important;
        transition: all 0.3s ease;
      }
    }
  }



  @include media-breakpoint-down(md) {
    height: 80px;

    /*  .container {
      max-width: 100% !important;
    } */

    .row-header {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    .brand img {
      max-width: 215px;
    }

    .branches-dropdown-container {
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0;
      display: none;

      .branches-dropdown {
        max-width: 100%;
        height: 25px;

        .dropdown-toggle {
          height: 25px;
          padding: 0;
          background: transparent;
          display: block;

          &::before {
            display: none;
          }

          .branch-name {
            height: 25px;
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 25px;
            padding: 0 15px;
            width: auto;

            i.fa {
              margin-left: 10px;
              font-size: 13px;
            }
          }

          .opening-info {
            background: #fff;
            width: 100%;
            display: none;
          }
        }

        &.show {
          z-index: 1;

          .dropdown-toggle {
            height: 75px;

            .opening-info {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0 15px;
              height: 55px;
              align-items: center;

              .open-until,
              .phone {
                border: none;
                font-size: 15px;
              }
            }
          }
        }

        .dropdown-menu {
          max-width: 100%;

          &.show {
            // left: -5px !important;
          }

          .branch-single {
            height: 86px;
            padding: 10px 15px;
          }
        }
      }
    }

    .wpml-ls.wpml-ls-legacy-list-horizontal {
      display: none;
    }
  }

  @include media-breakpoint-down(xs) {
    .branches-dropdown-container .branches-dropdown .dropdown-menu.show {
      left: -5px !important;
    }
  }
}

.sub-header {
  //   z-index: 2;
  position: relative;
  background-color: #E5E6EB;
  transition: height 0.3s ease-in-out;

  .search-bar {
    // position: absolute;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-right: 16px;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    z-index: 2;

    &:hover {
      color: #0f8283;
      transition: all 0.3s ease;
    }

    .search-icon {
      margin-left: 15px;
      width: 20px;
      min-width: 20px;
      margin-bottom: 5px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .search-btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  .product-search {
    z-index: 1;
    position: absolute;
    color: black;
    background-color: #fff;
    border: 1px solid gray;
    font-size: 14px;
    font-weight: 400;
    height: 50px;
    width: 100%;
    width: calc(100% - 30px);
    top: 0;
    left: 15px;
    transition: top 0.2s ease-in-out;
    max-height: 50px;
    opacity: 0;

    input {
      padding-left: 0;
      padding-right: 15px;
      height: 48px;
      width: 100%;
      border: none;

      &:focus {
        box-shadow: none;
      }
    }

    .icon-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      background: #fff;
      padding: 2px;
      text-align: right;

      .search-icon {
        height: 20px;
        width: 20px;
      }
    }

    .autoListe {
      position: absolute;
      background-color: white;
      max-height: 0;
      opacity: 0;
      top: 49px;
      left: -1px;
      width: calc(100% + 2px);
      border: 1px solid gray;
      border-top: none;
      border-bottom: none;
      transition: max-height 0.2s linear 0.2s, opacity 0.2s linear 0s;
      overflow: hidden;

      .autoListeItem {
        line-height: 35px;
        height: 35px;
        padding: 0 15px;
        cursor: pointer;

        &:last-of-type {
          border-bottom: 1px solid gray;
        }
      }
    }

    &::placeholder {
      color: gray;
    }

    &.active {
      top: 69px;
      max-height: 1000px;
      opacity: 1;

      .autoListe {
        max-height: 500px;
        opacity: 1;
        transition: max-height 0.2s linear 0.2s, opacity 0.2s linear 0.2s;
      }
    }

    // @include media-breakpoint-down(md) {
    //   height: 40px;
    //   width: 100vw;
    //   min-width: 100vw;
    //   right: -15px;
    //   left: inherit;
    //   .autoListe {
    //     top: 39px;
    //   }
    //   input {
    //     font-size: 12px;
    //     padding-left: 5px;
    //     height: 38px;
    //     width: 100%;
    //   }
    //   &.active {
    //     top: 39px;
    //   }
    // }
  }

  @media (max-width: 992px) {
    .search-bar {
      height: 40px;
    }

    .form-bar {
      padding: 0;
    }

    .product-search {
      height: 40px;
      //   min-width: 150%;
      //   right: -15px;
      padding: 0 10px;
      left: inherit;

      .autoListe {
        top: 39px;
      }

      input {
        font-size: 12px;
        padding-left: 5px;
        height: 38px;
        width: 100%;
      }

      &.active {
        top: 39px;
      }
    }
  }

  @media (max-width: 576px) {
    .product-search {
      height: 40px;
      width: 100vw;
      min-width: 100vw;
      right: 0;
      left: inherit;

      .autoListe {
        top: 39px;
      }

      input {
        font-size: 12px;
        padding-left: 5px;
        height: 38px;
        width: 100%;
      }

      &.active {
        top: 39px;
      }
    }
  }

  .menu-dropdown {
    user-select: none;

    .dropdown-toggle {
      height: 60px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;
      user-select: none;
      transition: color 0.3s ease-in-out;
      margin-right: 15px;

      &:first-of-type {
        padding-left: 15px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      // &::after {
      //   display: none;
      // }

      &::after {
        content: "";
        border-right: 1px solid #000;
        height: 12px;
        padding-left: 15px;
        border-left: none;
        border-bottom: none;
        border-top: none;
        margin-left: 0;
        vertical-align: 0;
      }

      &.active {
        i.fa.fa-angle-down {
          transform: rotate(-180deg);
        }
      }

      span.menu-name {
        font-size: 12px;
        text-transform: uppercase;
        font-family: "mark_pro";
        font-weight: 400;
      }

      i.fa {
        margin-left: 10px;
        transition: transform 0.3s ease-in-out;
      }
    }

    .menu {
      position: absolute;
      top: 60px;
      right: 0;
      left: 0;
    }

    .market_container {
      height: 350px;
      right: 470px;
      left: 0;

      .market-container {
        max-width: 310px;
        background: #fff;
        position: relative;
        z-index: 10;
        //   letter-spacing: 0.7px;

        &>div {
          height: 100%;
        }

        div.logo {
          //height: 50px;
          width: 100%;
          min-width: 100%;
          text-transform: uppercase;
          color: theme-color(black);
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          font-weight: 400;
          padding: 6px 20px;
          padding-left: 0;
          position: relative;
          cursor: pointer;
          flex: 1;
          min-height: 1px;

          a {
            height: 25%;
            min-height: 50px;
            display: flex;
            align-items: center;
          }

          &>div {
            width: 100%;
          }

          &>a {
            text-decoration: none;
            color: theme-color(black);
            letter-spacing: 0.3px;
            padding-left: 20px;
          }

          // a faire
          &.active {
            background-color: #ebebeb;
            width: 100%;
            min-width: 100%;

            a {
              color: #000000;
              text-decoration: none;
              font-weight: 700;
              position: absolute;
              width: 100%;
              height: 100%;
              padding-left: 20px;
            }
          }

          &.active {
            background-color: #ebebeb;
            font-weight: 700;

            span {
              // letter-spacing: 0.45px;
            }
          }
        }

        div.logo {
          color: theme-color(black);

          .category-link {
            padding: 6px 0 6px 20px;
            text-transform: initial;
          }

          &:hover {
            color: #000;
            text-decoration: none;

            span {
              color: #000;
            }
          }
        }

        .menu-column {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
          width: 301px;
          display: flex;
          flex-flow: column wrap;

          .category-link {
            height: 50px;
            min-height: 50px;
          }

          .category-image {
            height: 100%;
            display: none;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 10px;
            margin-right: 10px;

            &.active {
              display: block;
            }
          }

          &+.menu-column {
            display: block;
            width: 400px;

            .category-link {
              padding: 6px 12px;
              text-transform: initial;
              font-size: 12px;
              height: auto;
              min-height: auto;
            }

            &+.menu-column {
              display: block;
              border-right: none;
              width: calc(100% - 301px - 400px);

              .category-link {
                padding: 6px;
                height: auto;
                min-height: auto;
                text-transform: initial;

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    .categories-container {
      height: 100%;
      background: #fff;
      position: relative;
      z-index: 10;
      //   letter-spacing: 0.7px;

      &>div {
        height: 100%;
      }

      .category-link {
        // height: 50px;
        width: 100%;
        text-transform: uppercase;
        color: theme-color(black);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 400;
        padding: 16px 20px;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &>div {
          width: 100%;
        }

        &>a {
          text-decoration: none;
          color: theme-color(black);
          letter-spacing: 0.3px;
        }

        &:hover {
          background-color: #ebebeb;
        }

        &.active {
          background-color: #ebebeb;
          font-weight: 700;

          span {
            // letter-spacing: 0.45px;
          }
        }
      }

      a.category-link {
        transition: color 0.3s ease-in-out;
        color: theme-color(black);

        .category-link {
          padding: 6px 0 6px 20px;
          text-transform: initial;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .menu-column {
        border-right: 1px solid #ebebeb;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        background-color: #fff;
        width: 301px;
        display: flex;
        flex-flow: column nowrap;

        .category-link {
          height: 25%;
          min-height: 50px;
        }

        .category-image {
          height: 100%;
          display: none;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 10px;
          margin-right: 10px;

          &.active {
            display: block;
          }
        }

        &+.menu-column {
          display: block;
          width: 400px;

          .category-link {
            padding: 6px 12px;
            text-transform: initial;
            font-size: 12px;
            height: auto;
            min-height: auto;
          }

          &+.menu-column {
            display: block;
            border-right: none;
            width: calc(100% - 301px - 400px);

            .category-link {
              padding: 6px;
              height: auto;
              min-height: auto;
              text-transform: initial;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .suppliers-container,
    .certifications-container {
      height: auto;
      position: relative;
      z-index: 10;
      background: #fff;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .logo {
        height: 130px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: theme-color(black);
        margin-bottom: 20px;

        span {
          font-size: 14px;
          font-weight: 700;
        }

        &>div {
          width: 70%;
          height: 100px;
          margin-bottom: 10px;
          display: block;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        img {
          max-width: 80%;
          max-height: 100%;
          width: auto;
        }

        &:hover {
          text-decoration: none;
          color: theme-color(black);
          opacity: 80%;
        }
      }
    }

    .all-suppliers-link {
      color: black;
      display: block;
      background-color: #fff;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      width: 65%;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        width: 75%;
      }

      span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 11;
        padding: 0 10px;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: "";
        height: 1px;
        width: 100%;
        background-color: theme-color(grey-2);
      }
    }

    .resources-menu,
    .sds-menu,
    .order-online-menu {
      display: flex;
      align-items: center;
      font-size: 13px;
      cursor: pointer;

      a {
        text-decoration: none;
      }

      span {
        transition: all ease-in-out 300ms;
        text-transform: uppercase;
      }
    }

    .resources-menu,
    .sds-menu {
      margin-right: 15px;

      &::after {
        content: "";
        border-right: 1px solid #000;
        height: 12px;
        padding-left: 15px;
        border-left: none;
        border-bottom: none;
        border-top: none;
        margin-left: 0;
        vertical-align: 0;
      }
    }

    .sds-menu {
      &::after {
        display: none;
      }
    }
  }
}
