// Those variables are necessary for a troubleless experience with webpack
// Set font-family to the one of FontAwesome.
$slick-font-family: "FontAwesome";
// $slick-loader-path: "../images/";
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.slick-track,
.slick-list {
  height: 100%;
}

.slick-slide {
  a:focus {
    outline: none !important;
  }

  & * {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.slick-slider {
  width: 100%;
}

.slick-dots {
  li {
    margin: 5px 4px;
    width: 14px;
    height: 14px;

    button {
      border: 1px solid #0C7C83;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      padding: 0;

      &::before {
        display: none;
      }
    }
  }
}

.slick-prev.fa.fa-angle-left,
.slick-next.fa.fa-angle-right {
  text-align: center;
  font-size: 25px;
  height: 35px;
  display: none !important;

  &::before {
    color: theme-color(black);
    font-size: 35px;
  }
}
