.grand-total {
  padding-right: 0;
  margin-top: 15px;
  margin-bottom: 25px;
  .sub-total {
    border-top: solid 3px black;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 20px;
    border-bottom: solid 1px black;
    span {
      margin-left: 30px;
    }
  }
  .text {
    font-weight: 600;
    margin-right: 5px;
  }
  .after {
    left: 54%;
    position: absolute;
    &::after {
      position: relative;
      display: block;
      top: -28px;
      margin: auto;
      bottom: 33px;
      left: 48px;
      width: 0;
      height: 0;
      background-color: theme-color(black);
      content: "...";
    }
  }

  .tax {
    text-align: right;
    border-bottom: solid 1px black;
    padding: 7px 0;
    width: 100%;
  }
  .delivery-fees {
    text-align: right;
    border-bottom: solid 1px black;
    padding: 7px 0;
  }
  .total {
    font-size: 30px;
    font-weight: 600;
    padding-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
