.shop-root.sds,
.product-root.sds {
  margin-bottom: 30px;
  margin-top: 80px;
  min-height: 600px;
  .search {
    margin-bottom: 20px;
  }

  .search-line {
    margin-top: 20px;
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 19px;
    p {
      font-size: 15px;
      font-weight: normal;
    }
    .sds-search-input {
      width: 100%;
      border: none;
      padding: 5px;
      font-size: 16px;
    }
  }

  .sds-lines-header {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 12px;
  }
  .search-bar {
    position: relative;
    width: 90%;
    margin-top: 10px;
    padding: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 1px solid black;
    z-index: 2;
    input:focus + .autoListe {
      opacity: 1;
      max-height: 500px;
    }
    img {
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
    .autoListe {
      font-size: 15px;
      font-weight: normal;
      position: absolute;
      left: -1px;
      top: 45px;
      width: calc(100% + 2px);
      background: #fff;
      border: 1px solid black;
      border-top: none;
      border-bottom: none;
      z-index: 1;
      opacity: 0;
      overflow: hidden;
      max-height: 0;
      .autoListeItem {
        padding: 3px 10px;
        cursor: pointer;
        &:last-of-type {
          border-bottom: 1px solid black;
        }
      }
    }
  }

  .sds-line {
    padding: 10px 0;
    font-size: 13px;
    border-top: 1px solid black;
    min-height: 80px;

    .code {
      font-weight: 600;
    }

    .btn {
      width: 80%;
      color: white;
      i.fa {
        color: white;
        margin-right: 5px;
      }
    }
  }

  .name-code {
    .code,
    .name {
      width: 50%;
      height: 100%;
    }
  }

  .align {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
    }
  }
  & + .pre-footer-actions {
    display: none;
  }

  @media (max-width: 768px) {
    .sds-lines-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 12px;
      .name-code {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
      }
      .see-button {
        display: flex;
        justify-content: center;
        span {
          text-align: center;
        }
      }
    }
    .sds-line {
      display: flex;
      flex-flow: row nowrap;
      height: auto;
      font-size: 11px;
      padding: 10px 0;
      .name-code {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        .code,
        .name {
          width: 100%;
          height: auto;
        }
      }
      .see-button {
        display: flex;
        justify-content: center;
      }
      .btn {
        min-width: 60px;
        width: auto;
      }
    }

    .search-bar {
      width: 100%;
    }

    .align {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        text-align: left;
      }
    }
  }
}
