.page-sector-template {
  .customizable_content {
    position: relative;
    min-height: initial;
    margin-top: 0;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    p:empty {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 2rem;
      font-size: clamp(18px, 2vw, 50px);
      line-height: clamp(24px, 2.5vw, 56px);

      p {
        font-size: clamp(18px, 2vw, 50px);
        line-height: clamp(24px, 2.5vw, 56px);
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
      }
    }

    h3 {
      margin-bottom: 2rem;
      font-size: clamp(18px, 1.8vw, 50px);
      line-height: clamp(24px, 2.5vw, 56px);

      p {
        font-size: clamp(18px, 1.8vw, 50px);
        line-height: clamp(24px, 2.5vw, 56px);
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
          font-size: 1.4rem;
        }
      }
    }

    span {
      font-size: clamp(12px, 2vw, 20px);
      line-height: clamp(16px, 2.5vw, 26px);
      margin-bottom: 3rem;
    }

    h4 {
      margin-bottom: 2rem;
      font-size: clamp(18px, 2vw, 44px);
      line-height: clamp(24px, 2.5vw, 50px);

      p {
        font-size: clamp(18px, 2vw, 44px);
        line-height: clamp(24px, 2.5vw, 50px);
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
        }
      }
    }

    p {
      font-size: clamp(12px, 2vw, 20px);
      line-height: clamp(16px, 2.5vw, 26px);
      margin-bottom: 1rem;

      @include media-breakpoint-down(sm) {
        font-size: clamp(16px, 2vw, 30px);
        line-height: clamp(20px, 2.5vw, 36px);
        margin-bottom: 1rem;
      }

    }

    img {
      margin-top: 0;
    }
  }

  .icons_banner {
    position: relative;
    min-height: initial;
    margin-top: 0;
    padding: 60px 0;
    background-color: #0f8283;

    .icon_banner {
      img {
        margin-bottom: 30px;
        max-width: 208px;
        max-width: clamp(200px, 30vw, 300px);
      }

      h2 {
        font-size: clamp(24px, 2vw, 60px);
        line-height: clamp(30px, 2.5vw, 66px);
        margin-bottom: 20px;
        color: #fff;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        color: #fff;
      }
    }
  }

  .second_cutomizable {
    background-color: #ececec;
  }

  .recommendation_prduits {
    margin-bottom: 40px;

    .title_recommendation {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .sector_filter {
      .link_sector {
        font-family: 'mark_proe';
        font-weight: 700;
        text-transform: uppercase;
        height: 28px;
        transition: all .3s;

        &:hover {
          text-decoration: none;
          color: #0f8283;
          border-bottom: 3px solid #0f8283;
        }

        &.active {
          text-decoration: none;
          color: #0f8283;
        }
      }

    }

    .product_list {

      .slick-prev.fa.fa-angle-left,
      .slick-next.fa.fa-angle-right {
        display: inline-block !important;
      }
    }

    .item-recommandation {
      padding-right: 5px;
      padding-left: 5px;
    }

  }
}
