.btn,
button {
  font-size: 13px;
  white-space: initial;
  border-radius: 3px;
  font-weight: 600;

  &.btn-full {
    width: 100%;
  }

  &.btn-dark {
    @include button-variant(
      theme-color(black),
      theme-color(black),
      theme-color(grey-3),
      theme-color(grey-3),
      theme-color(grey-3),
      #fff
    );
  }

  &.btn-light {
    // @mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))
    @include button-variant(
      theme-color(green),
      theme-color(green),
      theme-color(green),
      theme-color(green),
      theme-color(green),
      #fff
    );
  }
}
