.logo-carousel {
  // display: flex;
  // flex-flow: row;
  margin-left: 40px;
  width: 70%;

  a.supplier-logos {
    display: inline-flex;
    justify-content: center;
  }

  .logo-box {
    height: 50px;
    width: 60px;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 992px) {
  .logo-carousel {
    margin-left: 0;
    justify-content: center;
  }
}
