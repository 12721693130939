.distributer {
  padding-top: 5rem !important;

  @media (max-width: 425px) {
    padding: 2rem !important;
  }

  @media (max-width: 375px) {
    padding: 1.2rem !important;
  }

  .container_map {
    .content_search {
      .wpgmza-store-locator {
        margin-bottom: 2px !important;

        @media (max-width: 425px) {
          font-size: 13pt;
        }

        .wpgmza-address-container {
          width: 100%;
          margin-bottom: 15px !important;

          @media (max-width: 425px) {
            margin-bottom: 10px !important;
          }

          @media (max-width: 375px) {
            margin-bottom: 10px !important;
          }

        }

        .wpgmza-search {
          margin-right: 2px !important;
          margin-bottom: 4px !important;

          @media (max-width: 425px) {
            margin-right: 1px !important;
          }
        }

        input.wpgmza-search,
        input.wpgmza-reset {
          background-color: #0C7C83;
          padding: 0px 12px !important;
          border-radius: 22px;
          color: #fff;
          text-decoration: none;
          border: none;
        }

        input.wpgmza-search {
          min-width: 100px;
        }

        input.wpgmza-reset {
          min-width: 90px;

          @media (max-width: 425px) {
            min-width: 120px;
          }
        }

        .wpgmza-address {
          color: #0C7C83;
          border: 2px solid #0C7C83;
          border-radius: 20px;
          padding-left: 10px !important;
          padding-right: 10px !important;
          width: 100%;

        }

        .wpgmza-radius {
          color: #0C7C83;
          border: none;
          border-right: 16px solid transparent;
          outline: 2px solid #0C7C83;
          border-radius: 20px;
          padding-left: 10px !important;
          padding-right: 10px !important;

          @media (max-width: 425px) {
            border-right: 8px solid transparent;
            padding-left: 6px !important;
            padding-right: 6px !important;
          }
        }

        .wpgmza-address::placeholder {
          color: #0C7C83;
        }

      }

      .wpgmza_marker_list_class {
        margin-bottom: 20px !important;

        .wpgmaps_blist_row {
          padding: 16px 0;
        }
      }
    }

    .map {
      .wpgmza_infowindow_description {

        p,
        a {
          margin-bottom: 8px;
          color: #0C7C83;
          outline: none;

          &:focus {
            outline: none;
          }
        }
      }

      .wpgmza_infowindow_title {
        margin-bottom: 5px;
        font-size: 13px;
        line-height: normal;
      }

      .wpgmza_infowindow_address {
        margin-bottom: 5px;
        font-size: 13px;
        line-height: normal;
      }

      .wpgmza_infowindow_description {
        p {
          font-size: 13px;
          line-height: normal;
        }
      }

      .wpgmza_infowindow_description+p,
      .wpgmza_infowindow_address+p {
        font-size: 13px;
        line-height: normal;

        a {
          outline: none;
        }
      }
    }

    .directions-box {
      margin: 30px auto;

      .wpgmza-directions-from,
      .wpgmza-directions-to {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .wpgmza-address {
          color: #0C7C83;
          border: 2px solid #0C7C83;
          border-radius: 20px;
          padding-left: 10px !important;
          padding-right: 10px !important;

          &::placeholder {
            color: #0C7C83;
          }
        }

        .wpgmza-use-my-location {
          opacity: 1 !important;
          background-color: #0C7C83;
        }
      }

      .wpgmza-travel-mode {
        color: #0C7C83;
        border: none;
        border-right: 16px solid transparent;
        outline: 2px solid #0C7C83;
        border-radius: 20px;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }

      .wpgmza-hidden {
        margin-left: 3px;
      }

      .wpgmza-directions-buttons {
        .wpgmza-get-directions {
          background-color: #0C7C83;
          padding: 0px 12px !important;
          border-radius: 22px;
          color: #fff;
          text-decoration: none;
          border: none;
          height: 1.6em;
          margin-top: 10px !important;

          @include media-breakpoint-down(md) {
            margin-top: 10px !important;
          }
        }
      }

      .wpgmza-directions-buttons>* {
        height: 2em;
        padding: 0 !important;
        display: flex;

        @include media-breakpoint-down(sm) {
          height: 1.6em;
        }
      }

      .wpgmza-directions-result__buttons {

        .wpgmza-print-directions,
        .wpgmza-reset-directions {
          background-color: #0C7C83;
          padding: 0px 22px !important;
          border-radius: 22px;
          color: #fff;
          text-decoration: none;
          border: none;
          height: 1.6em;
          margin-right: 16px;

          @include media-breakpoint-down(sm) {
            font-size: 14px;
            padding: 0px 10px !important;
            margin-right: 8px;
          }
        }
      }

      .wpgmza-directions-box {
        margin-bottom: 10px;
      }

      .adp-placemark {
        tbody {
          tr {
            td {
              padding-left: 15px;
            }

            .adp-text {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding-bottom: 0;
  padding-top: 30px;
  justify-content: end;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    padding-top: 20px;
  }

  .right {
    @media screen and (max-width: 425px) {
      margin-bottom: 8px;
    }
  }

  .text_footer {
    width: 100%;
    color: #000000;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 425px) {
      font-size: 16px;
      line-height: 21px;
    }

    a {
      color: #269097;
      text-decoration: none;

      span {
        color: #000000;
      }

      &:hover {
        color: #000000;

        span {
          color: #269097;
        }
      }
    }
  }
}
