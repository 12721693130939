.modal-dialog {
  .modal-content {
    padding: 70px;
    background-color: #f4f4f4;
    .modal-header {
      border: none;
      padding: 0;
    }
    .content-box {
      div.product-image {
        border: 1px solid black;
        height: 160px;
        padding: 10px;
        // width: 125px;
        background: #fff;
        div {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .product-description {
        margin-top: 50px;
        margin-bottom: 30px;
        .details {
          margin-left: 10px;
          .brand {
            font-size: 10px;
          }
          .code {
            font-weight: 700;
            font-size: 15px;
            text-decoration: none;
          }
          .name {
            font-size: 11px;
          }
          .logos {
            margin-top: 15px;
            img {
              height: 20px;
              background: transparent;
            }
          }
        }
      }
      .product-card-bottom {
        display: flex;
        flex-flow: column;
        align-items: center;

        /* .qty {
          font-size: 10px;
          font-weight: 600;
          padding-right: 0;

          input {
            font-size: 14px;
            background: transparent;
            width: 40px;
            height: 30px;
            border: none;
            border: 1px solid black;
            text-align: center;
            margin-left: 15px;
          }
        } */

        .add-box {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;

          // margin-top: 32px;
          margin-bottom: 20px;
          //   height: 60px;

          /* .price-box {
            align-items: center;
            color: theme-color(white);
            display: flex;
            flex-flow: column;
            font-size: 10px;
            padding: 10px 50px;

            p {
              margin-bottom: 5px;
            }

            .price-detail {
              display: flex;
              flex-flow: row nowrap;
              margin: 0 5px;

              .upper-price {
                font-size: 28px;
                line-height: 33px;
                margin: 0;
                padding: 0;
              }

              .lower-price,
              .dollar-sign {
                vertical-align: top;
                font-size: 15px;
                margin: 0;
                padding: 0;
              }
            }
            span {
              font-size: 28px;
              font-weight: 600;
              padding: 0 10px;
            }
          } */

          .format {
            display: flex;
            flex-flow: column;
            font-size: 12px;
            width: 100%;

            select,
            div {
              width: 100%;
              margin-top: 5px;
              height: 40px;
              background: none;
              border: 1px solid black;
              padding: 0 0 0 10px;
              outline: none;
              appearance: none;
              border-radius: 0;
              display: flex;
              align-items: center;
            }

            select {
              background-image: url("../../images/angle-down.png");
              background-size: 15px;
              background-position: 98% center;
              background-repeat: no-repeat;
            }
          }

          .qty {
            align-items: center;
            display: flex;
            font-size: 10px;
            flex-flow: row nowrap;
            text-align: center;
            width: 70px;
            min-width: 70px;
            justify-content: space-between;

            span {
              border: solid 1px theme-color(black);
              display: flex;
              flex-flow: column;
              font-size: 20px;
              margin-top: 4px;
              padding: 0 25px;
              text-align: center;
            }
            .qte-box {
              margin-left: 5px;
              width: 100px;
              font-size: 12px;
              font-weight: 500;
              display: flex;
              flex-flow: column;
              justify-content: center;

              input {
                margin-top: 5px;
                height: 40px;
                width: 100%;
                text-align: center;
                border: 1px solid black;
                font-weight: 500;
                background: transparent;
              }
            }
          }
        }
        button {
          background: transparent;
          color: black;
          height: auto;
          margin-top: 0 !important;
          //   margin-left: 20px;
        }
      }
      .logos {
        img {
          margin-right: 5px;
        }
      }
    }
    .delete {
      position: absolute;
      top: 35px;
      right: 35px;
      img {
        cursor: pointer;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 25px;
    }
  }
  @media (max-width: 767px) {
    .modal-content {
      padding: 30px;
    }
    h1.modal-title {
      font-size: 24px;
    }
  }
}
