html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  &.menu-open {
    // overflow-y: hidden;

    header.banner {
      z-index: 0;
    }
  }
}

h1 {
  position: relative;
  margin-bottom: 50px;
  color: #000;
  font-size: clamp(24px, 3vw, 60px);
  line-height: clamp(30px, 3.5vw, 66px);
  text-transform: uppercase;

  &:not(.no-undertitle)::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 50px;
    bottom: -20px;
    transform: initial;
    left: 0;

    @include media-breakpoint-down(sm) {
      transform: initial;
      margin: 0;
    }
  }
}

h2 {
  color: #000;
  font-size: clamp(18px, 2vw, 50px);
  line-height: clamp(24px, 2.5vw, 56px);
  text-transform: uppercase;
}

h3 {
  color: #000;
  font-size: clamp(18px, 2vw, 50px);
  line-height: clamp(24px, 2.5vw, 56px);
  text-transform: uppercase;
}

h2,
h3,
h4 {
  position: relative;
  margin-bottom: 50px;
  font-family: "mark_proe";
  font-weight: 700;

  &:not(.no-undertitle)::after {
    content: "";
    display: block;
    position: absolute;
    height: 5px;
    width: 50px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -20px;

    @include media-breakpoint-up(md) {
      transform: initial;
      margin: 0;
    }
  }
}

p,
span,
ul,
time {
  letter-spacing: 0.3px;
}

h2 {
  @include media-breakpoint-down(sm) {
    font-size: 1.6rem;
  }
}

h3 {
  @include media-breakpoint-down(sm) {
    font-size: 1.45rem;
  }

}

p {
  color: #000;  
  font-size: clamp(12px, 2vw, 20px);
  line-height: clamp(16px, 2.5vw, 26px);

  @include media-breakpoint-down(sm) {
    font-size: clamp(16px, 2vw, 30px);
    line-height: clamp(20px, 2.5vw, 36px);
  }
}

.mobile-center {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.text-center {

  h2,
  h3 {
    &:not(.no-undertitle)::after {
      @include media-breakpoint-up(md) {
        transform: initial;
        margin: auto;
      }
    }
  }
}

a {
  color: theme-color(black);
  transition: all .3s;

  &.as-block {
    width: 100%;
    height: 100%;
    display: block;

    * {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    transition: all .3s;
  }
}

.uppercase {
  text-transform: uppercase;
}

.first-uppercase {
  &::first-letter {
    text-transform: uppercase;
  }
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

.strong {
  font-weight: 700;
}

img.img-responsive {
  max-width: 100%;
  max-height: 100%;
}

%remove-hover-decoration {
  a:hover {
    text-decoration: none;
  }
}

.main-container {
  z-index: 0;
  position: relative;
  background-color: white;
  //   padding-top: 120px;
}

#header-dropdown-backdrop {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: -140px;
  height: 140px;
  background: transparent;
  z-index: 1000;
}

#branches-backdrop {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;

  &.dropdown-open {
    display: block;
  }

  &.display {
    opacity: 1;
  }
}

#dropdown-backdrop {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  bottom: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.cover-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contain-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

%underline-bottom-styling {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

%underline-bottom-styling-green {
  @extend %underline-bottom-styling;
}

%flex-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

//----- commun css to career, about us and green cleaning

.main-sub-section-items {
  margin-bottom: 80px;

  .content-title {
    border-bottom: none;
    border-right: none;
    border-left: none;
    padding-top: 80px;
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  .team-member-box {
    display: flex;
    flex-flow: row nowrap;
    color: #fff;
    min-height: 150px;

    .photo {
      height: 150px;
      width: 150px;
      min-width: 150px;
    }

    .profile-content {
      padding: 0 25px;
      width: 100%;
      line-height: 1.5em;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;

      .name {
        font-weight: 600;
        font-size: 1.4em;
      }

      .position {
        font-size: 1.1em;
        margin-bottom: 9px;
      }

      .email {
        color: #fff;
        font-weight: 600;
      }
    }

    @include media-breakpoint-down(md) {
      min-height: 120px;

      .photo {
        height: 120px;
        width: 120px;
        min-width: 120px;
      }

      .profile-content {
        font-size: 80%;
        padding: 5px 15px;
        // line-height: 20px;
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 1px;
    margin-top: 10px;
  }
}

.main-section-items .content-title,
.mailto-container {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.mailto-container {
  margin-top: 30px;
}

.second-section-items .content-title {
  @include media-breakpoint-down(sm) {
    left: 0;
    transform: initial;
    margin: 0;
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.page-content {
  margin-top: 30px;

  .main-section-items,
  .third-section-items {
    margin-bottom: 50px;
    font-size: 14px;

    .mb-5:last-of-type {
      margin-bottom: 0 !important;
    }

    .item-header {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      img {
        height: 50px;
        width: auto;
        margin-right: 20px;
      }
    }
  }

  .second-section-items {
    padding: 50px 0;

    .content-description {
      font-size: 22px;
    }
  }

  .third-section-items {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    text-align: center;

    .content-description {
      font-size: 15px;
      width: 60%;
    }
  }

  .content-description {
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.items-list {
  margin-top: 50px;
  font-size: 14px;

  li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    padding-left: 30px;

    &::after {
      display: inline-block;
      font: normal normal normal 14px/1;
      font-family: "FontAwesome", sans-serif;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\F1DB";
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto;
    }

    .fa-circle-thin {
      margin-right: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.mailto-container .btn {
  width: 160px;
}

/* WPML LANGUAGE SWITCHER */
.wpml-ls.wpml-ls-legacy-list-horizontal {
  border: none;
  padding: 0;

  .wpml-ls-item {
    display: flex !important;

    a {
      padding: 0 5px 0 10px;
      //border-left: 1px solid theme-color(black);
      margin-left: 10px;

      &:hover {
        span {
          &::after {
            color: #0f8283 !important;
            transition: all 0.3s ease;
          }
        }
      }
    }

    &.wpml-ls-item-fr a span,
    &.wpml-ls-item-en a span {
      display: block;
      width: 22px;
      height: 16px;
      line-height: 16px;
      overflow: hidden;
      position: relative;
      color: transparent;
      font-family: "mark_proe";
      font-weight: 700;

      &::after {
        display: block;
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-size: 14px;
        font-weight: 700;
        color: #000;
      }
    }

    &.wpml-ls-item-fr a span::after {
      content: "FR";
    }

    &.wpml-ls-item-en a span::after {
      content: "EN";
    }
  }
}

i.fa {
  &.fa-1-5x {
    font-size: 1.5em;
  }
}