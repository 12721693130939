.order-catalogue-page,
.ask-informations-page {

  p {
    padding-left: 0;
    font-size: clamp(12px, 2vw, 20px);
    line-height: clamp(16px, 2.5vw, 26px);
    margin-bottom: 30px;
    font-weight: 400;
  }

  h2 {
    margin-bottom: 40px;
    font-size: clamp(24px, 2vw, 60px);
    line-height: clamp(30px, 2.5vw, 66px);
  }

  .catalogue,
  .informations {
    height: 100%;

    .input-catalogue {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input {
        width: 46%;

        &:first-of-type {
          width: 50px;
          margin-right: 77%;
        }
      }

      select {
        width: 46%;
        height: 32px;
        background: #fff;
        color: #808080;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 600;
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
        background-image: url("../../images/angle-down.png");
        background-size: 15px;
        background-position: 99% center;
        background-repeat: no-repeat;
        outline: none;

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 21px;
        }
      }

      .custom-control {
        width: 46%;
        align-items: flex-start;
      }

      .number-catalogue {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 20px;
        align-items: center;

        span {
          margin-right: 10px;
          font-size: 12px;
        }

        input {
          min-width: 0;
          margin-bottom: 0;
          text-align: center;
          padding-left: 0;
          width: 60px;
        }
      }

      .validation {
        width: 50%;
        margin-left: 54%;

        .custom-control {
          display: flex;
          margin-bottom: 15px;
          flex-flow: column;
          align-items: flex-start;
          margin-top: 5px;
        }

        .btn {
          width: 200px;
          margin-bottom: 10px;
        }
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-flow: column;

        input {
          width: 100%;
        }

        .custom-control {
          width: 100%;
        }

        .validation {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }

    .content {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      padding-top: 56px;
      padding-bottom: 40px;

      input {
        background: #efefef;
        border: none;
        height: 38px;
        font-weight: 600;
        color: theme-color(black);
        font-size: 12px;
        padding-left: 18px;
        border-radius: 26px;
      }

      .left-ask-information {
        display: flex;
        flex-flow: column;
      }

      .right {
        display: flex;
        flex-flow: column;

        .heard-about span {
          margin-bottom: 20px;
        }

        .btn {
          width: 200px;
        }

        .textarea {
          width: 100%;
          background-color: #efefef;
          border: 0;
          padding: 14px 18px;
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 13px;
          border-radius: 26px;
        }

        .list {
          margin-top: 20px;

          .column {
            font-weight: normal;

            div {
              display: flex;
              align-items: center;
              margin-right: 15px;
              margin-bottom: 0;

              label {
                margin-left: 15px;
                margin-bottom: 0;
              }
            }
          }
        }

        .custom-control.solid-checkbox {
          font-weight: normal;
          align-items: flex-start;

          .input-line-catalogue {
            width: 75%;
            margin-top: 15px;
          }

          .custom-control-label {
            margin-bottom: 5px;
          }

          .col-left {
            width: 200px;
          }
        }

        .submit {
          margin-top: 20px;
          border: none;
          align-self: flex-start;
          padding: 6px 60px;
        }
      }

      @media (max-width: 992px) {
        flex-flow: column;
        display: block;
      }
    }
  }

  input:not([type="checkbox"]) {
    border: none;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 15px;
    height: 30px;
    padding-left: 10px;

    &.invalid {
      border: 1px solid theme-color(red);
    }

    &:last-child {
      margin-bottom: 30px;
    }

    &:focus {
      border: none !important;
      outline: none;
    }
  }

  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div:last-of-type {
      width: 100%;
    }

    .checkbox-line {
      margin-top: 0;
      width: 50%;
    }

    .custom-control.solid-checkbox {
      width: 45%;
      margin-top: 0;

      &:last-of-type {
        margin-top: 20px;
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-flow: column;
    }
  }
}
