@import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
@import "common/variables";
@import "common/slick-styling";
// $fa-font-path: "../fonts";
@import "~font-awesome/scss/font-awesome";
@import "common/bootstrap";

/** Import everything from autoload */
// @import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/checkbox";
@import "components/logo-carousel";
@import "components/download";
@import "components/wp-classes";
@import "components/popular-product";
@import "components/pre-footer-actions";
@import "components/categories-box";
@import "components/total-cart";
@import "components/carousel";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/tinymce";
@import "pages/home";
@import "pages/blog";
@import "pages/single-blog";
@import "pages/page-download";
@import "pages/contact";

//   ---------------------------
//   Sani Depot Added stuff
//   ---------------------------

@import "parts/page-header";
@import "pages/_shop";
@import "pages/_cart";
@import "pages/_sds";
@import "pages/_single-product";
@import "pages/_modal";
@import "pages/_calculator-modal";
@import "pages/_info-catalogue";
@import "pages/_faq";
@import "layouts/mobile-menu";
@import "pages/_ino-lines";
@import "pages/_sectors";
@import "pages/_about";
@import "pages/_distributer";
