div.shop-root .single-product,
div.product-root .single-product {
  /*  .col-left {
    padding: 0;
  } */
  min-height: 1000px;

  .single_product {
    position: relative;
  }

  .single-product-main-content {

    @include media-breakpoint-down(md) {
      //margin-bottom: 60px;
    }

    .medias {
      display: flex;
      //flex-flow: column;
      padding-top: 2.5rem;

      .medias-container {
        height: 350px;
        width: 100%;
        margin-bottom: 10px;
      }

      .video-container {
        height: 100%;
        background-color: theme-color(black);
      }

      .other-medias {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        &div:last-child {
          margin-right: 50px;
        }

        &>div {
          margin-bottom: 10px;
          width: 23%;
          height: 100px;
          margin-right: 10px;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          border: 1px solid #e6e6e6;
          position: relative;
          cursor: pointer;

          &:not(.image) {
            background-color: theme-color(black);
          }

          &:last-of-type {
            margin-right: 0;
          }

          &.active {
            border: 3px solid #0f8283;
          }

          i.fa {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 50px;
            height: 50px;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 50%;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          // @include media-breakpoint-down(md) {
          //   height: 300px;
          //   margin-bottom: 20px;
          //   border-width: 3px;
          // }

          .embed-responsive {
            height: 100px;
          }

          img {
            opacity: 0.6;
            height: 100%;
            object-fit: contain;
            width: 100%;
          }

          @include media-breakpoint-down(md) {
            width: 20%;
          }
        }

        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }
    }

    .product-description {
      background-color: #00727a;
      padding-top: 2.5rem;

      .description-social {
        position: absolute;
        top: 35px;
        right: 5px;
        display: flex;
        align-items: center;
        z-index: 9999999999999999;

        @include media-breakpoint-down(sm) {
          position: relative;
          top: 0;
          right: 0;
          margin: 0;
          padding: 0;
          margin-bottom: 15px;
          justify-content: flex-start;
        }

        span {
          color: #fff;
          margin-right: 10px;
        }

        .social-icons {
          background-color: #fff !important;
          width: 25px;
          height: 25px;
          border-radius: 25px;
        }

        .main-txt {
          color: #0f8283;
          padding-left: 0px;
          padding-top: 0px;

          &.fa-linkedin {
            padding-left: 1px;
          }
        }
      }

      .description-title {
        color: #fff;
        width: 70%;

        h1 {
          font-size: 1.75rem;
          line-height: 1.2;
          color: #fff;
        }

        h2 {
          margin-top: 0 !important;
          width: 70%;
          font-size: 1.1rem;
          line-height: 1.2;
          font-family: "mark_proe";
          font-weight: 400;
          margin-bottom: 25px !important;
          margin-top: 25px !important;
          color: #fff;
        }
      }

      .buttons_bio {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        a {
          background-color: #fff;
          padding: 7px 20px;
          border-radius: 18px;
          color: #000;
          -ms-flex-item-align: end;
          align-self: flex-end;
          text-decoration: none;
          font-size: 0.8rem;
          transition: all .3s;

          &:first-child {
            margin-left: 0rem !important;
          }

          @include media-breakpoint-down(sm) {
            margin-bottom: 0px;

            &:first-child {
              margin-left: 0rem !important;
            }

            &:nth-child(2) {
              margin-left: 0 !important;
              margin-top: 20px;
            }
          }

          &:hover {
            background-color: #0f8283;
            color: #fff;
          }
        }
      }

      .accordion {
        width: 75%;
        margin-top: 30px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin: 0;
          margin-top: 30px;
        }

        .card {
          border: none;
          background-color: transparent;

          .card-header {
            border: none;
            background-color: transparent;
            border-top: 1px solid #f6f6f8;
            border-radius: 0;
            padding-left: 0;

            .btn {
              padding-left: 0;
              text-decoration: none;
              color: #fff;
              text-transform: uppercase;
              font-family: 'mark_proe';

              &.collapsed {
                &::after {
                  -webkit-transform: rotate(-180deg);
                  -o-transform: rotate(-180deg);
                  transform: rotate(-180deg);
                }
              }

              &::after {
                content: "\f106";
                font-family: 'FontAwesome';
                display: block;
                color: #fff;
                position: absolute;
                bottom: initial;
                left: initial;
                top: 0;
                right: 0;
                width: initial;
                height: initial;
                font-size: 24px;
              }
            }
          }

          .card-body {
            padding: 0.5rem;
            padding-top: 0;
            padding-bottom: 1rem;
            color: #fff;

            .items-list {
              margin-top: 10px;

              .main-after:after {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .product-description {
    .description-add {
      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }
  }

  .product-image {
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    // margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      height: 100%;
      margin-bottom: 12px;
      border-width: 3px;
      margin-right: 10px;
    }

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .availability {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;

    p {
      letter-spacing: 1px;
    }

    span {
      font-weight: lighter;
      font-size: 10px;
      margin-left: 40px;
    }
  }

  .description-title {
    //padding-left: 15px;
    padding-top: 15px;

    h1 {
      position: relative;
      font-weight: 600;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .dissan-codes {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      flex-wrap: wrap;
      font-size: 12px;
      margin-top: 2px;
      left: 0px;
      min-height: 20px;

      &::after {
        display: none;
        content: "";
        height: 5px;
        left: -59px;
        top: 6px;
        position: absolute;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
        width: 50px;
        background: black;
      }

      .item {
        margin-right: 5px;
        font-size: 11px;
        font-weight: 600;

        &:not(:last-child)::after {
          content: ",";
        }
      }
    }

    h2 {
      margin: 10px 0;
      font-size: 1.5rem;
      font-weight: 600;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  .description-text {
    margin-bottom: 1rem;
    padding-left: 15px;
    display: flex;
    flex-flow: column;

    p {
      font-size: 13px;
    }

    span {
      font-size: 13px;
      font-weight: 600;
      margin-right: 25px;
    }

    a {
      margin-top: 15px;

      .msds {
        font-weight: 600;
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  .description-add {
    //padding-left: 15px;
    width: 100%;
    padding-right: 130px;

    .add-box {
      display: flex;

      // margin-top: 32px;
      margin-bottom: 30px;
      height: 75px;

      .price-box {
        align-items: center;
        color: theme-color(white);
        display: flex;
        flex-flow: column;
        font-size: 10px;
        padding: 10px 50px;

        p {
          margin-bottom: 5px;
        }

        .price-detail {
          display: flex;
          flex-flow: row nowrap;
          margin: 0 5px;

          .upper-price {
            font-size: 28px;
            line-height: 33px;
            margin: 0;
            padding: 0;
          }

          .lower-price,
          .dollar-sign {
            vertical-align: top;
            font-size: 15px;
            margin: 0;
            padding: 0;
          }
        }

        span {
          font-size: 28px;
          font-weight: 600;
          padding: 0 10px;
        }
      }

      .format {
        display: flex;
        flex-flow: column;
        font-size: 12px;
        margin-right: 12px;
        width: 57%;
        color: #fff;

        span {
          font-size: 14px;
        }

        select,
        div {
          width: 100%;
          margin-top: 5px;
          height: 50px;
          background: #fff;
          border: 1px solid #fff;
          color: #000;
          padding: 0 0 0 10px;
          outline: none;
          appearance: none;
          border-radius: 0;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-weight: 600;
        }

        select {
          background-image: url("../../images/angle-down.png");
          background-size: 15px;
          background-position: 94% center;
          background-repeat: no-repeat;
        }
      }

      .qty {
        align-items: center;
        display: flex;
        font-size: 10px;
        flex-flow: row nowrap;
        text-align: center;
        justify-content: space-between;
        width: 20%;

        span {
          border: solid 1px theme-color(black);
          display: flex;
          flex-flow: column;
          font-size: 20px;
          margin-top: 4px;
          padding: 0 25px;
          text-align: center;
        }

        .qte-box {
          font-size: 12px;
          font-weight: 500;
          display: flex;
          flex-flow: column;
          justify-content: center;
          width: 100%;

          input {
            margin-top: 5px;
            height: 37px;
            width: 100%;
            text-align: center;
            border: 1px solid black;
            font-weight: 500;
          }
        }
      }
    }

    .btn-darken {
      height: 40px;
      width: 100%;
      border: none;
      color: #fff;
      font-weight: 600;

      i.fa {
        margin-right: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .consult-section {
    margin-bottom: 65px;

    .consult {
      display: flex;
      flex-flow: row;
      align-items: center;
      font-size: 12px;

      .box {
        min-height: 50px;
        height: 1px;
        border-right: 3px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          border-right: none;

          span {
            color: theme-color(black);
            margin-left: 0;
          }
        }

        span {
          margin-left: 15px;
          font-weight: 700;
        }

        .link-box {
          a.website-link {
            position: relative;
            font-weight: 600;
            font-size: 12px;
            text-transform: initial;
          }
        }

        @include media-breakpoint-down(md) {
          min-height: 74px;
        }

        @include media-breakpoint-down(sm) {
          min-height: 50px;
        }
      }

      .description-header {
        min-height: 50px;
        height: 1px;
        display: flex;
        flex-flow: row nowrap;

        .description-logo {
          max-height: 40px;
          max-width: 40px;
        }

        @include media-breakpoint-down(md) {
          min-height: 68px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;

          img {
            margin-right: 10px;
          }

          span {
            margin-left: 0;
            display: block;
            text-align: center;
          }
        }

        @include media-breakpoint-down(sm) {
          min-height: 50px;
        }
      }

      .msds-box {
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        flex-flow: column;
        min-height: 100%;

        .box {
          border-right: none;
        }

        .description-header {
          .description-logo {
            max-height: 50px;
            max-width: 50px;
          }
        }
      }

      a {
        text-align: center;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }

  .item-features {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;

    div {
      position: relative;
      font-weight: 700;
      margin-right: 34px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -25px;
        height: 2px;
        width: 16px;
        margin: auto;
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap;
      text-align: left;

      div::after {
        display: none;
      }
    }
  }

  .single-product-details {
    align-items: center;
    margin-bottom: 90px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      background-color: #fff;
    }

    .video-details-container {
      width: 50px;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        flex-flow: column nowrap;
      }

      .video-container {
        max-width: calc(50% - 15px);
        margin-right: 15px;

        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 100%;
          min-width: initial;
          margin-right: initial;
        }

        &+.product-details {
          max-width: 50%;

          //   column-count: initial;
          @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
            margin-top: 15px;
          }
        }
      }

      .product-details {
        // margin-top: 50px;
        padding: 30px 0;
        // column-count: 3;
        // column-gap: 60px;
        width: 100%;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          //   column-count: initial;
          padding: 30px 15px;
          margin-left: 0;
        }

        .details {
          display: flex;
          flex-flow: row nowrap;
          font-size: 14px;
          break-inside: avoid;
          min-height: 40px;
          align-items: center;

          //   justify-content: center;
          p {
            font-weight: 600;
            position: relative;
            padding-right: 30px;
            margin-bottom: 0;
            white-space: nowrap;
            max-width: 55%;
            text-overflow: ellipsis;
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              right: 7px;
              height: 2px;
              width: 16px;
              margin: auto;
              background-color: #99dfe3;
              display: block;
            }
          }

          p,
          span {
            max-width: 50%;
          }

          //   span {
          // white-space: nowrap;
          // max-height: 40px;
          // text-overflow: ellipsis;
          // overflow: hidden;
          //   }

          /* &:last-of-type {
            p {
              margin-bottom: 0;
            }
          } */
        }
      }
    }
  }

  .description_container {
    background-color: #fff;
    padding-top: 4rem;

    @include media-breakpoint-down(sm) {
      padding-top: 0rem;
    }
  }

  .find-distributor {
    background-color: #3a3a3a;
    padding: 30px 0;

    .distributor {
      flex-direction: row;
      display: flex;

      .fa-map-marker {
        font-size: 114px;
        color: #008C99;
        margin-right: 30px;

        @media (max-width: 425px) {
          display: none;
        }

      }

      .form-distributor {
        width: 100%;
        padding-top: 10px;

        &-title {
          margin-bottom: 8px;
          color: #fff;
          text-transform: uppercase;

          @media (max-width: 425px) {
            text-align: center;
          }
        }

        form {
          .form-row {
            align-items: center;
            margin-left: 0;
            margin-right: 0;

            label {
              color: #fff;
              width: 100%;
              padding-right: 30px;
              text-transform: uppercase;
              font-size: 12px;
              font-family: 'mark_proe';
              font-weight: 700;

              @media (max-width: 425px) {
                padding-right: 0;
                text-align: center;
              }

              input {
                margin-top: 4px;
                border-radius: 20px;
                font-family: 'mark_proe';
              }

              select {
                margin-top: 4px;
                border-radius: 20px;
                font-family: 'mark_proe';
              }
            }

            .btn {
              margin-left: auto;
              border-radius: 20px;
              padding-left: 20px;
              padding-right: 20px;
              text-transform: uppercase;

              .fa-search {
                margin-left: 5px;
              }

              @media (max-width: 425px) {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .single-product-moredesc {
    padding-bottom: 30px;

    h2 {
      font-size: 2rem;
      line-height: 1.2;
      letter-spacing: 0.6px;
      margin-bottom: 20px;
      text-transform: initial;

      &::after {
        display: none;
      }
    }

    .col-left {

      .description-box {
        p {
          font-size: 14px;
          line-height: 1.5;
          padding-right: 50px;
        }
      }

      .formats-box {
        margin-bottom: 80px;

        span {
          font-size: 14px;
          font-weight: 600;
          margin-right: 20px;
          padding: 11px 20px;
        }
      }

      .dilution-box {
        border-left: none;
        border-right: none;
        margin-bottom: none;

        li {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin-bottom: 16px;
        }

        p {
          font-weight: 600;
          margin-bottom: 0;
        }

        span {
          display: block;
          position: relative;
        }

        p+span {
          padding-left: 30px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 7px;
            height: 2px;
            width: 16px;
            margin: auto;
            background-color: #c4c4c4;
            display: block;
          }
        }
      }
    }
  }

  .mb-detailbox {
    margin-bottom: 70px;
  }

  .documents-box {
    display: flex;
    flex-flow: column;

    a {
      margin-bottom: 10px;

      span {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  .pictos-box {
    margin-top: 20px;
    max-width: 40px;
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .certifications-container {
    padding-left: 0;
    margin-top: 10px;

    .certification-box {
      margin-top: 20px;
      max-height: 40px;
      margin-right: 20px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }

  .suggestion_container {
    @include media-breakpoint-down(md) {
      padding-right: 35px;
      padding-left: 35px;
    }
  }

  .related-products-container {
    padding: 45px 0;
    margin: 45px 0 0;
    border-bottom: none;
    border-right: none;
    border-left: none;

    @include media-breakpoint-down(sm) {
      border-top: none;
    }

    &+.related-products-container {
      margin-top: 0;
    }

    .suggestion_container {
      height: 520px;

      .slick-prev.fa.fa-angle-left,
      .slick-next.fa.fa-angle-right {
        display: inline-block !important;
      }
    }
  }

  .other-items-bottom {
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: space-between;
    // margin-top: 90px;
    margin-bottom: 0px;

    h2 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 20px;

      &::after {
        display: none;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .product-box {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius: 2px;
    position: relative;
    min-height: 180px;
    height: 450px;
  }

  .product-box-inner {
    border-bottom: none;
  }

  .product-box-inner .product-description {
    padding: 0;
  }

  .social-icons {
    cursor: pointer;
    margin-right: 10px;

    &:focus {
      outline: none;
    }
  }

  .mobile-box {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.21);
    cursor: pointer;
  }

  .mobile-display {
    // margin-bottom: 20px;

    .mb-detailbox {
      margin-bottom: 0;
      padding: 25px 15px;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }

    .description-box {
      border-top: none;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;

      &.title {
        //   letter-spacing: 1px;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        //   margin-bottom: 0;
        //   margin-top: 30px;
      }
    }

    i.fa {
      transition: transform 0.35s ease-in-out;
    }

    .collapsed i.fa:not(.different) {
      transform: rotate(-180deg);
    }

    .col-right {
      padding-right: 0;
      padding-left: 0;
    }

    .items-list {
      margin-top: 0;
      margin-bottom: 0;

      li {
        margin-bottom: 15px;
      }
    }
  }

  //   .online-shop {
  //     background-color: rgba(43, 156, 162, 0.9);
  //     .online-box {
  //       align-items: center;
  //       display: flex;
  //       flex-flow: row nowrap;

  //       .description-box {
  //         p {
  //           background-color: #e3f9fa;
  //           border: solid 1px #e3f3f4;
  //           column-count: 2;
  //           column-gap: 60px;
  //           font-size: 14px;
  //           padding: 50px;
  //         }
  //       }

  //       .box-title {
  //         align-items: center;
  //         color: #fff;
  //         font-size: 45px;
  //         margin-top: 140px;
  //         margin-bottom: 140px;
  //       }
  //     }
  //   }
}
