.categories-box,
.shop-root .categories-container,
.product-root .categories-container {
  padding-top: 15px;
  padding-bottom: 15px;

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .col-6 {
    margin-bottom: 30px;

    // &:last-child,
    // &:nth-last-child(2) {
    //   margin-bottom: 0;
    // }
  }

  .order-sm-0 {
    order: unset !important;
  }
  .order-sm-1 {
    order: unset !important;
  }

  @media screen and (max-width: 991px) {
    .order-sm-0 {
      order: 0 !important;
    }
  }

  @media screen and (max-width: 991px) {
    .order-sm-1 {
      order: 1 !important;
    }
  }

  .col-lg-6,
  .col-sm-6 {
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(md) {
    // padding: initial;
    width: 100%;
  }

  @extend %remove-hover-decoration;

  a {
    display: block;
  }

  .cover-image {
    background-color: theme-color(black);
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: -1;
    left: 0;
    top: 0;
  }

  .box {
    transition: all 0.3s ease-in-out;
    color: white;
    text-align: center;
    text-transform: uppercase;
    min-height: 140px;
    position: relative;
    padding: 15px;
    height: 1px;

    @media screen and (max-width: 325px) {
      padding: 0;
    }



    @extend %flex-center;

    .title {
      position: relative;
      font-size: 20px;
      line-height: 25px;
      text-shadow: 1px 1px 1px #333;

      @media screen and (max-width: 325px) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        max-width: 330px;
      }

      &::after {
        content: "";
        display: block;
        border-bottom: 2px solid;
        position: absolute;
        padding-bottom: 10px;
        bottom: -8px;
        width: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      .title {
        &::after {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      min-height: 320px;
      position: relative;
    }
  }
}

// .categories-box-min-height {
//   @include media-breakpoint-up(md) {
//     min-height: 760px;
//   }
// }
