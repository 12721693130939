.blog {
  .breadcrumbs_wordpress {
    display: none;
  }

  main {
    //padding-top: 80px;
    padding-bottom: 30px;
    min-height: 70vh;

    .content-border-space {
      @include media-breakpoint-down(sm) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .title_news {
      margin-top: 40px;
      margin-bottom: 33px;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 41px;
    }

    .breadcrumb_container {

      margin-top: -15px;

      .breadcrumb_new {
        display: flex;

        .breadcrumb-item {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.5px;
          line-height: 17px;
        }

        .content_item_li {
          &::before {
            content: "<";
            color: #007c85;
            padding-right: 0.6rem;
          }
        }

        .home_sanit {
          color: #007c85;
          // background-image: url("../images/home.svg");
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          width: 16px;
          height: 16px;
          background-size: 100%;

          &:hover {
            // background-image: url("../images/home_gris.svg");
          }
        }

        .item_id_page {
          color: #007c85;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 17px;
          text-align: justify;

          &:hover {
            color: #0f8283;
          }
        }

        .active {
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 17px;
          text-align: justify;
          color: #000;
        }
      }
    }

  }

  .row {
    .whole_block {
      border-top: 1px solid #007c85;
      border-bottom: 1px solid #007c85;
      padding-top: 40px;
      padding-bottom: 40px;

      .cover-image {
        width: 100%;
      }
    }
  }

  .blocks {
    &:first-of-type {
      margin: 0;
    }

    &:last-of-type {
      margin-top: 60px;
    }

    .tags {
      list-style: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row;
      flex-wrap: wrap;
      padding: 0;
      position: absolute;
      top: 14px;
      right: 0;
      height: auto;
      width: 70%;
      justify-content: flex-end;

      p.tag {
        width: initial;
        height: initial;
        margin-bottom: 4px;
        margin-right: 4px;
        flex: 0 1 auto;
        background-color: #0C7C83;
        padding: 0px 14px;
        border-radius: 18px;
        color: #fff;
        -ms-flex-item-align: end;
        align-self: flex-end;
        text-decoration: none;
        text-transform: capitalize;

      }
    }

    .slick-prev.fa.fa-angle-left,
    .slick-next.fa.fa-angle-right {
      display: inline !important;
    }

    .slick-dots {
      bottom: -35px;
    }
  }

  .pagination_news {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 20px 0;
    padding-right: 10px;

    ul.page-numbers {
      display: inline-flex;
      margin-top: 15px;
      margin-bottom: 15px;

      li {

        a.prev,
        a.next {
          border: none;
          padding: 0.2rem 0.5rem;
          border-radius: 5px;
          //color: theme-color(primary);

          &:hover {
            background-color: theme-color(primary);
            color: #fff;
            border: none;
            text-decoration: none;
            outline: none !important;
          }
        }

        span.current {
          background-color: theme-color(primary);
          color: #fff;
          border: none;
          text-decoration: none;
          outline: none !important;
        }

        span.page-numbers,
        a.page-numbers {
          border: none;
          padding: 0.2rem 0.5rem;
          border-radius: 5px;
          //color: theme-color(primary);

          &:hover {
            background-color: theme-color(primary);
            color: #fff;
            border: none;
            text-decoration: none;
            outline: none !important;
          }
        }
      }

      span.news_point {
        font-size: 30px;
        line-height: 20px;
        margin: 0 4px;

        &:last-child {
          display: none;
        }
      }
    }
  }

  .block {
    .news {
      border: 1px solid #d4d8dd;
      width: 100%;
      height: 100%;

      &:hover {
        border: 1px solid #d4d8dd;
        background-color: rgba(190, 221, 223, 0.3);
      }
    }

    .card {
      background-color: transparent;
      border: none;
      min-height: 100%;

      .card-body {
        .text_last {
          padding-bottom: 70px;

          p {
            margin-bottom: 32px;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 24px;
          }
        }

        .button_more {
          position: absolute;
          bottom: 0;
          margin-bottom: 32px;

          .btn {
            width: 160px;
            display: block;
            font-size: 13px;
          }
        }
      }
    }
  }

  article {
    position: relative;
    // border-top: 20px solid black;
    // padding: 50px 60px 50px 90px;
    // margin-bottom: 60px;
    margin-bottom: 10px;
    min-height: 270px;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
    }

    .card {
      .img_news {
        width: 100%;
      }
    }

    &.loading {

      header,
      .entry-summary {
        opacity: 0;
      }
    }

    header,
    .entry-summary {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    time {
      font-size: 16px;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: block;
      font-weight: 400;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   background: theme-color(black);
      //   height: 5px;
      //   width: 50px;
      //   bottom: -20px;
      //   transform: initial;
      //   left: 0;
      // }
    }

    h2.entry-title {
      color: black;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;

      &::after {
        //display: none;
        content: "";
        background: theme-color(primary);
      }

      @include media-breakpoint-down(sm) {
        font-size: 25px;
      }
    }

    .text_last {
      font-size: 14px;
      letter-spacing: 0;
    }

    .lien_post_news {
      width: 160px;
      height: 30px;
      border-radius: 2px;
      background-color: #181818;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.6px;
      line-height: 15px;
      text-align: center;
      padding: 8px 50px;
      margin-top: 24px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    .saved-excerpt {
      display: none;
    }

    &.closed {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      header {
        width: 47%;
        padding-right: 15px;

        .entry-title {
          margin-bottom: 0;
        }
      }

      .entry-summary {
        width: 53%;
        padding-left: 15px;

        .cover-image {
          height: 150px;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-flow: column nowrap;

        header,
        .entry-summary {
          width: 100%;
        }

        header {
          padding-right: 0;
          margin-bottom: 20px;
        }

        .entry-summary {
          padding-left: 0;
        }
      }
    }

    .attachment-post-thumbnail {
      max-width: 100%;
      height: auto;
      margin-top: 30px;
    }
  }

  #load-more-section {
    width: 100%;
    height: 1px;
  }
}
