/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$theme-colors: (
  primary: #27929a,
  black: #181818,
  black-2: #0a0a0a,
  white: #fff,
  green: #27929a,
  green-2: #359ca1,
  pale-green: #e3f3f4,
  grey: #f1f1f1,
  grey-2: grey,
  grey-3: #464646,
  red: #ff5166
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
