.home {
  .home-head {
    // overflow: hidden;
    position: relative;
    min-height: initial;
    margin-top: 0;

    // @include media-breakpoint-up(md) {
    //   margin-top: 45px;
    // }
    /*
    Removes white gap between slides - chagnge to base color of slide images
    */
    .carousel {
      background: #ffffff;

      ol.carousel-indicators {
        bottom: -55px;
      }
    }

    /*
    Forces image to be 100% width and not max width of 100%
    */
    .carousel-item .img-fluid {
      width: 100%;
    }

    .carousel-indicators {
      li {
        margin: 5px 4px;
        width: 14px;
        height: 14px;

        border: 1px solid #0C7C83;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        padding: 0;

        &::before {
          display: none;
        }
      }

      .active {
        background-color: #0f8283;
      }
    }

    .carousel-caption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;
      padding-bottom: inherit;

      @include media-breakpoint-down(sm) {
        position: relative;
      }

      .content-caption {
        max-width: 50%;

        h1 {
          margin: 0;
        }

        p {
          margin: 1rem 0;
        }

        .text_hero {
          font-size: clamp(14px, 2vw, 30px);
          line-height: clamp(16px, 2.5vw, 36px);
        }

        @include media-breakpoint-down(lg) {
          max-width: 60%;
        }

        @include media-breakpoint-down(md) {
          max-width: 65%;
        }

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }

    /*
    anchors are inline so you need ot make them block to go full width
    */
    .carousel-item div {
      picture {
        img {
          @include media-breakpoint-down(sm) {
            min-height: 225px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .cover-image {
      @include media-breakpoint-down(sm) {
        background-position: left;
      }
    }
  }

  hr.line {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-width: 2px;
    background-color: #000;
    opacity: 0.5;
  }

  .ino_family {
    width: 100%;
    max-height: auto;
    margin-top: 70px;
    margin-bottom: 90px;

    .ino_familles {
      height: auto;

      h2 {
        margin-bottom: 1rem;
      }

      p {
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        margin-bottom: 3rem;
      }

      #corrousel_ino {
        margin-bottom: 45px;

        a {
          max-width: 228px;
          width: 100%;
          text-decoration: none;

          .icon_container {
            height: 80px;
            width: 80px;
            margin: 0 auto;
            margin-bottom: 6px;

            .image_icon {
              max-width: 100%;
              object-fit: contain;
              object-position: center;
            }
          }

          .logo_container {
            height: 84px;
            width: 228px;
            margin: 0 auto;
            margin-bottom: 6px;

            .logo_famille {
              max-width: 100%;
              object-fit: contain;
              object-position: center;
            }
          }

          .title_icon {
            margin: 0;
            color: #000;
            font-family: "mark_proe";
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            margin-bottom: 12px;
          }

          .text_icon {
            margin: 0;
            color: #000;
            text-align: center;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            font-family: "mark_proe";
          }
        }

        .slick-prev.fa.fa-angle-left,
        .slick-next.fa.fa-angle-right {
          display: inline !important;
        }
      }
    }
  }

  .history-box {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f0f1f5;
    margin-top: 70px;
    background-position: 100% 72%;
    background-size: cover;
    background-repeat: no-repeat;

    img {
      width: 100%;
    }
    .history-box__image-desktop {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .history-box__image-mobile {
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .content_history {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 40px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }

      &.carousel-caption {
        position: absolute;
        right: 2%;
        bottom: 15%;
        top: 15%;
        left: inherit;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        max-width: 42%;

        @include media-breakpoint-down(md) {
          max-width: 50%;
          right: 0%;
        }

        @include media-breakpoint-down(sm) {
          max-width: 55%;
          right: 3%;
        }

        h2,
        p {
          align-self: flex-end;
          text-align: right;
          margin-bottom: 1.4rem;
        }
      }


    }
    &.history-box--responsive {
      .content_history.carousel-caption {
        @include media-breakpoint-down(sm) {
          max-width: none;
          right: 0%;
          left: 0;
          top: 0;
          bottom: 0;
          max-width: none;
          position: relative;
          width: 100%;
          padding-top: 60px;
          padding-left: 20px;
          padding-right: 20px;
          h2,
          p {
            text-align: center;
            align-self: center;
          }

          .btn_all {
            align-self: center;
          }
        }
      }
    }

    picture {
      img {
        @include media-breakpoint-down(sm) {
          min-height: 360px;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &#our-history {
      picture {
        img {
          @media (max-width: 670px) {
            min-height: 420px;
          }
          @media (max-width: 540px) {
            min-height: 460px;
          }
          @media (max-width: 480px) {
            min-height: 520px;
          }
          @media (max-width: 414px) {
            min-height: 560px;
          }
          @media (max-width: 360px) {
            min-height: 620px;
          }
          @media (max-width: 320px) {
            min-height: 660px;
          }
          @media (max-width: 280px) {
            min-height: 760px;
          }
        }
      }
      .history-box__image-mobile {
        img {
          height: auto;
          min-height: 0;
          object-fit: cover;
        }
      }
    }
  }

  .information-box {
    position: relative;
    min-height: 298px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #FEFEFE 0%, #E4E4E4 100%);
    margin-top: 70px;
    border: 1px solid #E5E6EB;

    .content_history {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 40px;
      max-width: 470px;
      margin: auto;

      h2 {
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
        font-size: clamp(12px, 2vw, 20px);
        line-height: clamp(16px, 2.5vw, 26px);
        font-weight: 700;
      }

      .button_history {
        background-color: #0C7C83;
        padding: 8px 24px;
        border-radius: 18px;
        color: #fff;
        text-decoration: none;

        .fa-angle-right {
          margin-left: 6px;
        }
      }
    }

  }

  .img_cov_19 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 320px;
    position: relative;

    @include media-breakpoint-down(sm) {
      min-height: 140px;
      padding: 15px;

    }

    &:hover {
      background-color: rgba(15, 130, 131, 0.7);
    }
  }

  .img_cov_19:hover .title_protection::after {
    width: 100%;
  }

  .image_cov_19 {
    height: 100%;
    width: 100%;
    background-color: #181818;
    background-position: center;
    position: absolute;
    background-size: cover;
    z-index: -1;
  }

  .title_protection {
    max-width: 461px;
    text-shadow: 1px 1px 1px #333;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 50px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 25px;
    }

    &::after {
      content: "";
      display: block;
      border-bottom: 2px solid;
      padding-bottom: 10px;
      bottom: -8px;
      width: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  .container_cov_19 {
    background-color: #EFEFEF;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 36px;

    @media screen and (max-width: 425px) {
      height: auto;
      padding: 32px 16px;
    }

    h2 {
      margin-bottom: 40px;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 41px;
      text-align: justify;

      @media screen and (max-width: 375px) {
        font-size: 26px;
      }

      @media screen and (max-width: 320px) {
        font-size: 22px;
        line-height: 22px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 5px;
        width: 50px;
        left: 0;
        background-color: #0f8283;
        margin: 0;
      }

    }
  }

  .text-covid_19 {
    color: #3a3a3a;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 24px;
    text-align: left;

    @media screen and (max-width: 320px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .lien_cov_19 {
    width: 160px;
    height: 30px;
    border-radius: 2px;
    background-color: #181818;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 15px;
    text-align: center;
    padding: 8px 0;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      background-color: #464646;
      border-color: #464646;
    }
  }

  .why_sany {
    width: 100%;
    max-height: auto;
    background-color: #EFEFEF;

    .container_why_sany {

      .content_why {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      .title_why {
        margin-bottom: 31px;
      }

      .ico_why {
        margin-bottom: 23px;
      }

      .text_why {
        width: 200px;
        color: #3A3A3A;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 26px;
        text-align: center;
      }
    }

  }

  .home-new-products {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 490px;

    @include media-breakpoint-up(md) {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    .slick-prev.fa.fa-angle-left,
    .slick-next.fa.fa-angle-right {
      display: inline !important;
    }

    .new-products-title {
      text-align: center;

      h2 {
        font-weight: 700;

        &::after {
          display: none;
        }
      }

      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }
  }

  .nouvelles {
    margin-top: 70px;
    margin-bottom: 70px;
    padding-right: 0;

    @media screen and (max-width: 769px) {
      padding-left: 20px;
    }

    @media screen and (max-width: 425px) {
      padding-left: 25px;
    }

    h2 {
      margin-bottom: 50px;
      font-weight: 700;
    }

    .slick-prev {
      text-align: center;
      font-size: 25px;
      height: 35px;
      display: inline !important;

      @media screen and (max-width: 575px) {
        left: -8px !important;
        z-index: 100;
      }

    }

    .slick-next {
      text-align: center;
      font-size: 25px;
      height: 35px;
      display: block !important;

      @media screen and (max-width: 575px) {
        right: -8px !important;
        z-index: 100;
      }
    }

    .slick-list {
      .slick-slide {
        .block {
          .news {
            border: none;
            width: 100%;
            height: 100%;

            &:hover {
              border: none;
              background-color: none;
            }
          }

          .card {
            background-color: transparent;
            border: none;
            min-height: 100%;

            a {
              text-decoration: none;

              &:hover {
                color: #181818;
              }

              .card-body {

                .entry-title {
                  font-size: 1.6rem;
                  line-height: 2rem;
                  margin-top: 16px;
                  font-family: "mark_pro_bold";
                  margin-bottom: 24px;
                }

                .text_last {
                  padding-bottom: 70px;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }

    .link_news {
      text-decoration: none;

      .arrow_news {
        border: 1px solid #007279;
        border-radius: 50%;
        height: 19px;
        width: 18px;
        text-align: center;
        margin-left: 6px;

        &::before {
          font-size: 12px;
          color: #007279;
          margin: 0;
          padding: 5px;
        }
      }
    }
  }

  .cover-image.green {
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .logos-footer-section {
    .content-box {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;

      .static-logos {
        padding-top: 20px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .title {
          margin-right: 60px;
        }

        .logos {
          display: flex;
          flex-flow: row nowrap;

          img {
            margin-right: 35px;
          }
        }
      }

      .dynamic-logos {
        padding-top: 20px;
        padding-bottom: 30px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        border-bottom: none;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: #fff;
          top: 0;
          right: -100%;
        }

        .title {
          width: 30%;
          margin-left: 30px;

          &::after {
            left: 0;
          }
        }
      }

      .title {
        font-size: 15px;
        font-weight: 600;
        position: relative;
        display: flex;
        flex-flow: column;

        &::after {
          position: absolute;
          content: "";
          display: block;
          height: 5px;
          width: 100px;
          left: 0;
          right: 0;
          // margin: auto;
          top: 50px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .logos-footer-section {
      .content-box {
        height: 100%;
        display: flex;
        flex-flow: column;

        .static-logos {
          justify-content: flex-start;
          flex-flow: column;

          .title {
            margin-left: 0;
            margin-right: 0;
          }

          .logos {
            display: flex;
            justify-content: center;
            align-items: center;

            :first-child {
              margin-right: 50px;
            }

            img {
              margin-right: 0;
            }
          }
        }

        .dynamic-logos {
          flex-flow: column;

          .title {
            margin-left: 0;
          }

          .logo-carousel {
            justify-content: center;
          }

          &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 300px;
            background-color: #fff;
            top: 0;
            left: -299px;
          }

          &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 300px;
            background-color: #fff;
            top: 0;
            right: -299px;
          }
        }

        .title {
          width: auto;
          flex-flow: row;
          margin-bottom: 55px;

          span:first-child {
            margin-right: 5px;
          }

          &::after {
            top: 45px;
            width: 50px;
            margin: auto;
          }
        }
      }
    }
  }
}

.btn_all {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: #0C7C83;
  padding: 12px 32px;
  border-radius: 26px;
  color: #fff;
  -ms-flex-item-align: end;
  align-self: flex-end;
  text-decoration: none;
  border-color: #0C7C83;
  text-transform: uppercase;


  .fa-angle-right {
    margin-left: 6px;
  }

  @media screen and (max-width: 425px) {
    font-size: 12px;
    line-height: 16px;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #464646;
    border-color: #464646;
  }
}
