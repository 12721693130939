.custom-control.solid-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:first-of-type {
    margin-top: 15px;
  }
  .custom-control-label {
    cursor: pointer;
    font-size: 13px;
    &::before {
      top: 0;
      bottom: 0;
      margin: auto;
      width: 14px;
      height: 14px;
      background-color: transparent;
      box-shadow: none !important;
    }
    &::after {
      top: 0;
      bottom: 0;
      margin: auto;
      width: 14px;
      height: 14px;
      border: 1px solid theme-color(black);
    }
  }
}
