.download-box-container {
  position: relative;
  padding: 25px;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  min-height: 120px;

  @include media-breakpoint-down(sm) {
    padding: 15px;
    flex-flow: row wrap;
  }

  &.white {
    background-color: white;
  }

  .download-description {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 590px;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      max-width: calc(100% - 65px);
    }

    > * {
      align-self: flex-start;
    }

    .title {
      font-size: 20px;
      display: inline-block;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-weight: 600;
      border-top: none;
      border-right: none;
      border-left: none;
    }

    .description {
      font-size: 14px;
    }
  }

  .icon {
    width: 50px;
    height: 55px;
  }

  .btn-container {
    min-width: 180px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      width: 100%;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .download-icon {
    width: 95px;
    height: 95px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    background-size: 50%;
    // background-image: url("../../images/download-icons/default.svg");

    @include media-breakpoint-down(sm) {
      width: 65px;
      height: 65px;
      background-size: 65%;
    }

    &.doc {
      background-image: url("../../images/download-icons/doc.svg");
    }

    &.pdf {
      background-image: url("../../images/download-icons/pdf.svg");
    }

    &.xls {
      background-image: url("../../images/download-icons/xls.svg");
    }

    &.img {
      background-image: url("../../images/download-icons/img.svg");
    }

    &.video {
      background-image: url("../../images/download-icons/video.svg");
    }

    &.zip {
      background-image: url("../../images/download-icons/zip.svg");
    }
  }
}
