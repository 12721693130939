.cart {
  margin-top: 40px;

  textarea {
    border: 1px solid #000;
    margin-bottom: 20px;
    resize: none;
    width: 100%;
    height: 100px;
    padding: 5px;
  }

  .empty-cart,
  .submitted {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
    span {
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 30px;
    }
    .icon {
      margin-bottom: 30px;
    }

    &::before {
      top: -22%;
      z-index: -1;
      position: absolute;
      content: "";
      display: flex;
      width: 420px;
      height: 420px;
      border-radius: 50%;
      background-color: #f6f6f6;
    }
    .btn {
      font-size: 15px;
      width: 250px;
      height: 40px;
    }
  }
  @media (max-width: 768px) {
    .empty-cart,
    .submitted {
      span {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .icon {
        margin-bottom: 20px;
        img {
          height: 80px;
        }
      }
      &::before {
        top: -12%;
        z-index: -1;
        position: absolute;
        content: "";
        display: flex;
        width: 240px;
        height: 240px;
        border-radius: 50%;
        background-color: #f6f6f6;
      }
      .btn {
        font-size: 11px;
        width: 150px;
        height: 30px;
      }
    }
  }

  .items-container {
    .cart-header {
      justify-content: flex-end;
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 10px;
      span {
        margin-right: 82px;
      }

      span:nth-child(2) {
        margin-right: 40px;
      }
    }
    .item-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px theme-color(black);
      border-top: solid 1px theme-color(black);

      .item-details {
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 40%;
        .photo {
          padding: 10px 5px;
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
          a > div {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        .content-box {
          margin-left: 15px;
          display: flex;
          flex-flow: column;
          font-size: 12px;
          width: 100%;
          a {
            .title {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 1px;
            }
            &:hover {
              text-decoration: none;
            }
          }
          .code {
            span {
              margin-right: 5px;
            }
          }
        }
        .code {
          display: flex;
          align-items: center;
          flex-flow: row wrap;
          .item {
            margin-right: 3px;
          }
        }
      }
      .item-description {
        width: 40%;
        font-size: 12px;
        height: 100px;
        display: flex;
        flex-flow: row;
        align-items: center;

        .description {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-weight: 600;
          background: #ebebeb;
          width: 100%;
          margin-right: 14px;
          padding: 0 10px;
          text-align: center;
        }

        .delivery {
          display: flex;
          flex-flow: column;

          .custom-control-input {
            position: absolute;
            z-index: -1;
            opacity: 0;
          }
        }
      }

      .price-section {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 20%;
        div {
          height: 100%;
          margin-right: 25px;
          display: flex;
          align-items: center;
        }
        .price-unity {
          background: #ebebeb;
          padding: 0 15px;
        }

        .quantity {
          display: flex;
          flex-flow: column;
          align-items: center;
          form .input-qte {
            font-weight: normal;
            font-size: 14px;
            height: 30px;
            width: 50px;
            text-align: center;
          }
          .remove {
            margin-top: 5px;
          }
          :last-child {
            letter-spacing: 1px;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            text-transform: capitalize;
          }
        }
        .total {
          padding: 0 15px;
          background: black;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
        :last-child {
          margin-right: 0;
        }
        i {
          cursor: pointer;
        }
        .delete-row {
          margin-left: 20px;
          .delete-cross {
            right: 5%;
            position: absolute;
            border: 1px solid black;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            cursor: pointer;
            top: 35px;
            &::after {
              position: relative;
              width: 40px;
              right: 25%;
              height: 1px;
              background-color: theme-color(black);
              content: "";
              transform: rotate(45deg);
            }
            &::before {
              position: relative;
              right: -25%;
              width: 40px;
              height: 1px;
              background-color: theme-color(black);
              content: "";
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    :nth-child(n + 3) {
      border-top: none;
    }
  }

  .bottom-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 90px;

    .rules-container {
      font-size: 12px;
      span {
        font-weight: 700;
        margin-left: 2px;
      }
    }

    .grand-total {
      margin: 0;
      .submit {
        margin: 0;
        float: right;
      }
    }

    .submit {
      display: flex;
      width: 200px;
      justify-content: center;
      font-size: 15px;
      background: black;
      color: #fff;
      margin-top: 20px;
    }

    .product-table {
      margin-bottom: 30px;

      table,
      td,
      th {
        border: 1px solid #000;
      }

      td {
        padding: 5px;
      }

      tr {
        height: 30px;
        color: #fff;
        &:nth-child(even) {
          background: #ebebeb;
        }
      }
      table {
        margin-top: 10px;
        width: 100%;
        .title {
          text-align: center;
        }
        input {
          border: none;
          background: transparent;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .bottom-container {
      .grand-total {
        margin: 0;
        .submit {
          margin: 0;
        }
      }
    }
    .items-container {
      .item-container {
        .item-details {
          width: 35%;
        }
        .item-description {
          flex-wrap: wrap;
          width: 45%;
          .delivery {
            span {
              font-size: 13px;
              font-weight: 600;
              margin-bottom: 5px;
              margin-top: 5px;
            }
          }
        }
      }
      .cart-header {
        span {
          margin-right: 62px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .items-container {
      .item-container {
        flex-wrap: wrap;
        .item-description {
          //   border-top: 1px solid theme-color(black);
          display: none;
          font-weight: 600;
          font-size: 20px;
          width: 100%;
          order: 3;
          height: 70px;
          //   background: #ebebeb;
          .description {
            display: none;
          }
        }
        .item-details,
        .price-section {
          width: 50%;
          order: 1;
        }
        .delivery {
          flex-flow: row;

          span {
            font-size: 13px;
            margin-right: 20px;
          }
        }
      }
      .cart-header {
        :first-child {
          margin-right: 142px;
        }
      }
    }
    .bottom-container {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      .submit {
        width: 100%;
      }

      .rules-container {
        order: 2;
      }
      .grand-total {
        margin-top: 20px;
        order: 1;
        margin-bottom: 60px;
        padding-right: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    .bottom-container {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      .grand-total {
        margin-top: 20px;
      }
      .submit {
        width: 100%;
      }
    }
    .items-container {
      .item-container {
        display: flex;
        flex-flow: column;
        padding: 15px 0;
        .item-details {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
        .price-section {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          width: 100%;
          font-size: 15px;
          justify-content: flex-start;
          span {
            margin-top: 7px;
            margin-left: 23px;
            margin-right: 35px;
            font-size: 10px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  select {
    height: 32px;
    color: #808080;
    padding-left: 10px;
    margin-bottom: 15px;
    font-size: 11px;
    font-weight: 600;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    background-image: url("../../images/angle-down.png");
    background-size: 15px;
    background-position: 99% center;
    background-repeat: no-repeat;
    outline: none;
  }
}

.submission-page {
  display: flex;
  flex-flow: row;

  .title {
    font-weight: 600;
  }

  .left {
    .billing,
    .shipping {
      display: flex;
      flex-flow: column;
      margin-top: 15px;
      input:not([type="checkbox"]) {
        border: none;
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 15px;
        height: 30px;
        padding-left: 10px;

        &.invalid {
          border: 1px solid theme-color(red);
        }

        &:last-child {
          margin-bottom: 30px;
        }
        &:focus {
          border: none !important;
          outline: none;
        }
      }

      .delivery-fields-container {
        display: flex;
        flex-flow: column;
      }
    }

    .rules-container {
      font-size: 12px;
      span {
        font-weight: 700;
      }
    }
  }
  .right {
    display: flex;
    flex-flow: column;

    .items {
      margin-bottom: 30px;
      .item {
        margin-top: 10px;
        padding: 5px 0;
        border-top: 1px solid black;
        .up-row {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          font-size: 15px;
          font-weight: 600;
        }
        .down-row {
          display: flex;
          font-size: 11px;
          span {
            margin-right: 8px;
          }
          .dissan-code {
            display: flex;
            .format {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .submit {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 15px;
    background: black;
    color: #fff;
    margin-top: 20px;
  }
  .custom-control.solid-checkbox.radio {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-bottom: 1px solid black;
    .radio-section {
      width: 100%;
      margin-bottom: 15px;
      :first-child {
        margin-bottom: 10px;
      }
      .option {
        .custom-control-label {
          &::before {
            top: 0;
            bottom: 0;
            margin: auto;
            width: 14px;
            border-radius: 7px;
            height: 14px;
            background-color: transparent;
            box-shadow: none !important;
          }
          &::after {
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 7px;
            width: 14px;
            height: 14px;
            border: 1px solid theme-color(black);
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          // background-color: theme-color(red);
          border-radius: 7px;
        }
      }
    }
  }
  .custom-control.solid-checkbox {
    margin-top: 15px;
  }

  .comments {
    margin-top: 30px;
    span {
      font-weight: bold;
      font-size: 11px;
    }
    textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 992px) {
  .submission-page {
    display: flex;
    flex-flow: column;
    margin-bottom: 40px;
  }
}
