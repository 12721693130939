.pre-footer-actions {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    opacity: 0.5;
  }

  &>.container {
    position: relative;
    z-index: 10;
  }

  a:hover {
    text-decoration: none;
  }

  .box {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .action-box {
    max-height: 175px;
    padding: 15px;
    box-shadow: 0 0 10px 0 rgba(24, 24, 24, 0.3);
    display: flex;
    flex-direction: row;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    border-radius: 2px;

    @include media-breakpoint-up(lg) {
      padding: 40px 25px;
    }

    .action-box-icon {
      cursor: pointer;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: white;
      overflow: hidden;
      flex-shrink: 0;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        width: 40px;
        height: 40px;
      }

      @include media-breakpoint-up(lg) {
        width: 95px;
        height: 95px;

        img {
          width: 55px;
          height: 55px;
        }
      }
    }

    .action-box-text {
      display: flex;
      align-items: center;
      flex-grow: 1;
      text-decoration: none;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }
    }

    &:hover {
      background-color: white;
      color: theme-color(black);
      //   font-weight: 600;

      .action-box-icon {
        transform: scale(1.1);
        background-color: transparent;
      }
    }

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }
}

.home-newsletter {
  min-height: 230px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: left;
  }

  @include media-breakpoint-down(sm) {
    min-height: 300px;
  }

  h4 {
    margin-bottom: 20px;
    font-size: clamp(22px, 3vw, 38px);
    line-height: clamp(30px, 3.5vw, 36px);
    letter-spacing: 3px;
    color: #fff;

    &::after {
      display: none;
    }
  }

  .description {
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
    font-size: clamp(16px, 2vw, 18px);
    line-height: clamp(20px, 3vw, 26px);
    font-weight: 700;

    @include media-breakpoint-up(lg) {
      margin-bottom: initial;
      text-align: left;
    }
  }

  .input-container {
    position: relative;

    input {
      width: 100%;
      height: 50px;
      border: none;
      padding: 10px 60px 10px 20px;
      border-radius: 2px;
      font-size: 20px;
    }

    .arrow {
      position: absolute;
      top: 0;
      right: 35px;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      background-color: theme-color(black);
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 13px;
        height: 2px;
        border-radius: 1px;
        background-color: theme-color(black);
      }

      &::before {
        transform: rotate(45deg);
        transform-origin: center right;
      }

      &::after {
        transform: rotate(-45deg);
        transform-origin: center right;
      }

      input {
        position: absolute;
        background-color: transparent;
        width: 40px;
        height: 50px;
        left: -25px;
        top: -24px;
        cursor: pointer;
      }
    }

    .CIMessage {
      position: absolute;
      left: 0;
      top: 110%;
      font-size: 12px;
      user-select: none;
      padding-left: 5px;
    }
  }
}
