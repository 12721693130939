.contact-page-content {
  padding: 50px 0;
  h1 {
    margin-bottom: 50px;
  }
  hr {
    width: 15%;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 30px;

    &.title {
      margin-top: 0;
      margin-bottom: 15px;
      position: relative;
    }
  }

  .maps {
    // margin-bottom: 45px;
    height: 100%;

    .cover-image {
      height: 100%;
      display: block;
      min-height: 650px;
    }
  }

  .right-column {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    & > div {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .store-information {
    margin-bottom: 30px;

    .infos-box {
      text-align: center;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        width: 30px;
        margin: auto;
        display: block;
      }
    }

    .contact-box {
      font-weight: 600;
      margin-top: 20px;
      font-size: 20px;
      text-align: center;
    }
    .postal-code-and-country {
      margin-bottom: 12px;
    }
  }

  .opening-hours {
    padding: 40px 0;
    min-height: 330px;
    .title {
      &::after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        height: 2px;
        width: 30px;
        margin: auto;
        display: block;
      }
    }
    .week-box {
      margin-top: 20px;
      .day-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 6px;

        .day {
          font-size: 16px;
          font-weight: 600;
          text-align: right;
          margin-right: 10px;
          width: 120px;
        }

        .times {
          font-size: 16px;
          min-width: 150px;
          text-align: left;
        }
      }
    }
  }
}
